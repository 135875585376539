// HalfCircleProgressBar.js
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import './CircularProgress.css';

const CircularProgress = ({ complexity }) => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={6}>
          <div style={{ width: '100%', height: 'auto', transform: 'rotate(-0deg)' }}>
            <CircularProgressbar
              value={complexity}
              text={`${complexity}%`}
              circleRatio={0.75}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                strokeLinecap: "butt",
                trailColor: "#eee"
              })}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CircularProgress;
