import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Mission = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mission | Leichte Sprache Übersetzer</title>
        <meta name="description" content="Mission - Leichte Sprache Übersetzer" />
        <meta name="keywords" content="Mission, Leichte Sprache Übersetzer, Einfache Sprache" />
        <meta property="og:title" content="Mission - Leichte Sprache Übersetzer" />
        <meta property="og:description" content="Mission - Leichte Sprache Übersetzer" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
      </Helmet>
      <article className="">
        <h1 className="text-4xl font-bold mb-6 text-center">{t('mission.title')}</h1>
        <h2 className="text-xl font-semibold mb-8 text-center" dangerouslySetInnerHTML={{ __html: t('mission.subtitle') }}></h2>
        
        <div className="space-y-6 text-lg">
          <p dangerouslySetInnerHTML={{ __html: t('mission.intro') }}></p>
          <div className="flex justify-center items-center">
            <img src="/imgs/other/helping-hand-2.webp" alt="A helping hand" className="max-w-full h-auto" width="864" height="584"/>
          </div>
          <h2 className="text-xl font-semibold mb-4">{t('mission.currentSituation.title')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('mission.currentSituation.paragraph1') }}></p>
          <p dangerouslySetInnerHTML={{ __html: t('mission.currentSituation.paragraph2') }}></p>
          <p dangerouslySetInnerHTML={{ __html: t('mission.currentSituation.paragraph3') }}></p>
          <h2 className="text-xl font-semibold mb-4">{t('mission.challenges.title')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('mission.challenges.paragraph1') }}></p>
          <p dangerouslySetInnerHTML={{ __html: t('mission.challenges.paragraph2') }}></p>
          <p dangerouslySetInnerHTML={{ __html: t('mission.challenges.paragraph3') }}></p>
          <p dangerouslySetInnerHTML={{ __html: t('mission.challenges.paragraph4') }}></p>
          <h2 className="text-xl font-semibold mb-4">{t('mission.vision.title')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('mission.vision.paragraph') }}></p>
          <h2 className="text-xl font-semibold mb-4">{t('mission.approach.title')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('mission.approach.paragraph') }}></p>
        </div>
      </article>
    </div>
  );
};

export default Mission;
