import React, { useState, useEffect } from 'react';

const ReadingProgressBar = () => {
  const [width, setWidth] = useState(0);

  const scrollHandler = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;
    setWidth(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <div 
      className="fixed left-0 h-1 bg-primary transition-all duration-300 ease-out z-40"
      style={{ 
        width: `${width}%`,
        top: '64px' // This assumes you have a CSS variable for navbar height
      }}
    />
  );
};

export default ReadingProgressBar;