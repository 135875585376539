import React, { useState, useEffect } from 'react';
// import { Tooltip } from 'react-bootstrap';
import '../../App.css';
import { useTheme } from '../../context/ThemeContext';

const RadioButtonGroup = ({ defaultValue, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  // const renderTooltip = (props, text) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     {text}
  //   </Tooltip>
  // );

  return (
    <div className="text-center">
      <p className="text-base mb-2">Kürzung</p>
      <div className="flex items-center justify-center">
        <label className="flex flex-col items-center hover:bg-gray-200 dark:hover:bg-gray-800 rounded-md cursor-pointer p-2" htmlFor="stark">
          <div className="relative group flex flex-col items-center">
            <input
              type="radio"
              name="summarization"
              id="stark"
              className="peer cursor-pointer"
              value="2"
              checked={selectedValue === '2'}
              onChange={handleChange}
            />
            <span className="mt-1 text-sm group-hover:underline group-hover:decoration-black group-hover:underline-offset-8"
                  title="Kürzt den Text stark. Umfasst nur die wichtigsten Informationen.">
              Stark
            </span>
          </div>
        </label>

        <div className="w-4 h-0.5 bg-primary-color mx-0.5 mt-2.5"></div>

        <label className="flex flex-col items-center hover:bg-gray-200 dark:hover:bg-gray-800 border-b-gray-800 rounded-md cursor-pointer p-2" htmlFor="mittel">
          <div className="relative group flex flex-col items-center">
            <input
              type="radio"
              name="summarization"
              id="mittel"
              className="peer cursor-pointer"
              value="1"
              checked={selectedValue === '1'}
              onChange={handleChange}
            />
            <span className="mt-1 text-sm group-hover:underline group-hover:decoration-black group-hover:underline-offset-8"
                  title="Kürzt den Text leicht. Umfasst die meisten Informationen.">
              Leicht
            </span>
          </div>
        </label>

        <div className="w-4 h-0.5 bg-primary-color mx-0.5 mt-2.5"></div>

        <label className="flex flex-col items-center hover:bg-gray-200 dark:hover:bg-gray-800 rounded-md cursor-pointer p-2" htmlFor="nicht">
          <div className="relative group flex flex-col items-center">
            <input
              type="radio"
              name="summarization"
              id="nicht"
              className="peer cursor-pointer"
              value="0"
              checked={selectedValue === '0'}
              onChange={handleChange}
            />
            <span className="mt-1 text-sm group-hover:underline group-hover:decoration-black group-hover:underline-offset-8"
                  title="Der vereinfachter Text hat in etwa die Länge des Originaltextes.">
              Keine
            </span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default RadioButtonGroup;
