import React from 'react';
import AuthorCard from './AuthorCard';

const LukasCard = () => (
  <AuthorCard
    name="Pascal Haller"
    imageUrl="/imgs/other/pascal-foto.webp"
    description="Pascal begegnet als Führungskraft im Sozialwesen täglich Menschen mit kognitiven und sprachlichen Einschränkungen."
  />
);

export default LukasCard;
