import React from 'react';

const AuthorCard = ({ name, imageUrl, description }) => (
  <a href='/uber-uns' className='no-underline text-black dark:text-gray-100' target="_blank" >
    <div className="flex w-full items-center bg-white dark:bg-background-dark border border-gray-200 dark:border-gray-700 rounded-lg p-4 mt-8 hover:shadow-md">
      <img src={imageUrl} alt={`Bild von ${name}`} className="w-24 h-24 rounded-full mr-4 object-cover" width="96" height="96" />
      <div>
        <h3 className="text-xl font-bold mb-2 text-gray-900 dark:text-gray-100">{name}</h3>
        <p className="text-gray-700 dark:text-gray-300 text-sm">{description}</p>
      </div>
    </div>
  </a>
);

export default AuthorCard;
