// src/data/postsData.js
const termsHurraki = [
  "Leichte Sprache",
  "Abbruchkante",
  "Abendland",
  "Abendrot",
  "Abfall",
  "Abfalltrennung",
  "Abfindung",
  "Abgeordnete",
  "Abgeordnete",
  "Abgeordneten",
  "Abgeordnetengesetz",
  "Abgeordneter",
  "Abhören",
  "Abkürzung",
  "Abraham Lincoln",
  "Abrahams Schoß",
  "Abrollschuh",
  "Abschiebung",
  "Abschiebung (Recht und Gesetz)",
  "Abspann",
  "Abspecken",
  "Abstauber",
  "Abstinenz",
  "Abstoß",
  "Abteilung",
  "Abwrackprämie",
  "Achttausender",
  "Acquired Immune Deficiency Syndrome",
  "Ad acta",
  "Ad hoc",
  "ADAC",
  "Adam Ries",
  "ADG",
  "ADHS",
  "Adjektiv",
  "Adoption",
  "Adrett",
  "Adventskalender",
  "Adverb",
  "Aerobic",
  "AFAIK",
  "Affäre",
  "AFK",
  "Afrika",
  "AGB",
  "AGG",
  "Aggressor",
  "Ägide",
  "Agil",
  "Ägypten",
  "Ahnungslos",
  "Aids",
  "Airbnb",
  "Akklimatisation",
  "Akku",
  "Akronym",
  "Akt",
  "Aktenvernichter",
  "Aktenzeichen XY",
  "Aktie",
  "Aktion Mensch",
  "Aktivist",
  "Akzeptieren",
  "Aladin und die Wunderlampe",
  "Alan Paton",
  "Alan Turing",
  "Albert Camus",
  "Albert Hofmann",
  "Alcopop",
  "Aldous Huxley",
  "Aleppo",
  "Alevit",
  "Aleviten",
  "Alexander Mitscherlich",
  "Alexander Sergejewitsch Puschkin",
  "Alexander Sutherland Neill",
  "Alexander Tatarnikov",
  "Alex Honnold",
  "Algorithmus",
  "Alice Miller",
  "Alice Salomon",
  "Allee",
  "Alleinstehend",
  "Alleinunterhalter",
  "Allenthalben",
  "Aller Anfang ist schwer",
  "Allerheiligen",
  "Alles",
  "Alles in Butter",
  "Allgemeines Gleichbehandlungsgesetz",
  "Alltagsmaske",
  "Altenburg",
  "Alter",
  "Alternative",
  "Alternative für Deutschland",
  "Altersarmut",
  "Alte Welt",
  "Ältestenrat",
  "Altruismus",
  "Altstadt",
  "Altweibersommer",
  "Aluminiumhut",
  "Alzheimer - Krankheit",
  "Alzheimer",
  "Amazoned",
  "Ambivalenz",
  "Ambulanz",
  "Amnesty International",
  "Amok",
  "Amoklauf von Trollhättan",
  "Ampelkoalition",
  "Amtssprache",
  "Amtszeit",
  "Anakonda",
  "Anarchismus",
  "Analphabet",
  "Anamnese",
  "Anerkennen",
  "Anerkennung",
  "Angela Merkel",
  "Anglizismus",
  "Angriff in Las Vegas",
  "Animal Liberation Front",
  "Ankerland",
  "Anlauttabelle",
  "Anna Faris",
  "Annalena Baerbock",
  "Anonym",
  "Anonymität",
  "Anonymous-Gruppe",
  "Anorexie",
  "Anrainer",
  "Anschlag am 1. Januar 2017 Istanbul",
  "Anschlag am 12. Januar 2016 in Istanbul",
  "Anschlag am 10. Dezember 2016 in Istanbul",
  "Anschlag auf Charlie Hebdo",
  "Anschlag auf den Bus von Borussia Dortmund",
  "Anschlag in Berlin 2016",
  "Anschläge in Brüssel am 22. März 2016",
  "Anschlag in Ankara 2015",
  "Anschlag in Ankara 2016",
  "Anschlag in Charlottesville",
  "Anschlag in London 2017",
  "Anschlag in London am 22.März 2017",
  "Anschlag in London am 3. Juni 2017",
  "Anschlag in Paris am 20. April 2017",
  "Anschlag in Ouagadougou 2016",
  "Anschlag in Tunis am 24 November 2015",
  "Anschlag in Sousse",
  "Anschlag in Straßburg 2018",
  "Anschlag in Suruc",
  "Ansturm",
  "Anthropozän",
  "Antibabypille",
  "Antibiotika",
  "Antidiskriminierungsgesetz",
  "Antischall",
  "Antisemitismus",
  "Antithese",
  "Anwerbeabkommen",
  "AN-12 Unfall in Juba",
  "Anzüglich",
  "Apartheid",
  "Apartheidspolitik",
  "Aperitif",
  "Apfel",
  "Aphasie",
  "Apoplex",
  "Aporie",
  "Apotheke",
  "App",
  "Apparat",
  "Apple Watch",
  "Apple",
  "Applizieren",
  "Apposition",
  "Après-Ski",
  "Aquajogging",
  "Aquaman",
  "Ära",
  "Arbeitgeber",
  "Arbeitsbeschaffungsmaßnahme",
  "Arbeitszeitkonto",
  "Arche Noah",
  "Arecibo-Botschaft",
  "Argument",
  "Arkanum",
  "Armin Laschet",
  "Armutsgrenze",
  "Arno Gruen",
  "Arschbombe",
  "Arschfax",
  "Arschgeweih",
  "Artikel",
  "Artikel 1 Grundgesetz",
  "Artikel 2 Grundgesetz",
  "Artikel des Grundgesetzes",
  "Arzneimittelausgabe",
  "Arzt",
  "Aschenputtel",
  "Aschermittwoch",
  "Asien",
  "Assistent",
  "Assistenz",
  "Ästhetik",
  "Asthma",
  "Astrid Lindgren",
  "Asyl",
  "Asylantrag",
  "Asylbewerber",
  "Antagonist",
  "Atelier",
  "Atheismus",
  "Atombombenabwurf auf Hiroshima",
  "Atomkraft",
  "Attentat",
  "Attest",
  "Attitüde",
  "Attribut (Grammatik)",
  "Auf Achse (Fernseh·serie)",
  "Auf dünnem Eis",
  "Aufmüpfig",
  "Aufruhr",
  "Auf einer Wellenlänge",
  "Auf Spitz und Knopf stehen",
  "Aufstehen",
  "Auftakt",
  "Auf zwei Planeten",
  "Augenmaß",
  "Augenweide",
  "Augenzeuge",
  "Aus allen Wolken fallen",
  "Ausbildung",
  "Auschwitz",
  "Aus den Augen, aus dem Sinn",
  "Außenpolitik",
  "Außenohr",
  "Ausgangssperre",
  "Aussitzen",
  "Aussprachedatenbank",
  "Aussprache·störung",
  "Austria",
  "Ausverkauf",
  "Autismus",
  "Autistic Pride Day",
  "Auto",
  "Autobahnvignette",
  "Autokärtchen",
  "Autokorso",
  "Automatikuhr",
  "Autor",
  "Autovervollständigung",
  "Avatar",
  "Avatar (Bild)",
  "Aya Iwamoto",
  "Aylan Kurdi",
  "B2K",
  "Baby",
  "Baby-Boomers",
  "Babyklappe",
  "Babys",
  "Babysitter",
  "Backflip",
  "Backofen vorheizen",
  "Badewannenrennen",
  "Badminton",
  "Bafög",
  "Baggy Pants",
  "Bahaitum",
  "Bakterien",
  "Balkanroute",
  "Ballaststoffe",
  "Ballade",
  "Ballermannsteuer",
  "Banal",
  "Bananen",
  "Bananenrepublik",
  "Bänderer",
  "Bandeaukini",
  "Bank",
  "Bankgeheimnis",
  "Bank (Möbel)",
  "Bankautomat",
  "Banksy",
  "Barack Obama",
  "Barber Angels",
  "Barbusig",
  "Barcamp",
  "Bärendienst",
  "Barf",
  "Barfüßigkeit",
  "Barista",
  "Barriere",
  "Barriere (Hindernis)",
  "Barrieren",
  "Barrierefreie Informationstechnik Verordnung",
  "Barrierefreiheit",
  "Barrierefreie Mobilität",
  "Barthaar",
  "Basketball",
  "Batman",
  "Batterie",
  "Bauchladen",
  "Bauchredner",
  "Bauernfängerei",
  "Beachvolleyball",
  "Beamtenstippe",
  "Beamter",
  "Beatnik",
  "Beauftragte der Bundesregierung für die Belange behinderter Menschen",
  "BeDoKW",
  "Beerdigung",
  "Befreiung von Auschwitz",
  "Begehren",
  "Begleitagentur",
  "Begleiterkrankung",
  "Behandlung",
  "Behindertenbeauftragte",
  "Behindertenbeauftragter",
  "Behindertenbeauftragter der Bundesregierung",
  "Behindertenfahrdienst",
  "Behindertengleichstellungsgesetz",
  "Behindertenkonvention",
  "Behindertenreitsport",
  "Behindertenwerkstatt",
  "Behinderung",
  "Behörde",
  "Beipackzettel",
  "Bekennerschreiben",
  "Belange",
  "Belichtungsdauer",
  "Benjamin Netanjahu",
  "Bentō",
  "Beratung",
  "Bergwerk",
  "Bericht",
  "Berlin",
  "Berlin-Blockade",
  "Berliner Erklärung (Alliierte)",
  "Berliner Mauer",
  "Bertolt Brecht",
  "Beruf",
  "Berufsgenossenschaft",
  "Besatzungszone",
  "Bescherung",
  "Bescheid",
  "Beschönigen",
  "Bestattung",
  "Bestellerprinzip",
  "Bestseller",
  "Bestsellerliste",
  "Betagt",
  "Betreutes Wohnen",
  "Betreuungseinrichtung",
  "Betriebsblindheit",
  "Betriebssystem",
  "Bettelampel",
  "Betthupferl",
  "Bettler",
  "Bevölkerungsdichte",
  "Bewegungsprofil",
  "Bewohnerbeirat",
  "Bewundern",
  "Beziehungsarbeit",
  "BGB",
  "Behindertengleichstellungsgesetz",
  "Bianca Castafiore",
  "Bibel",
  "Bibliophilie",
  "Bibliothek",
  "Bier",
  "Bierdeckel",
  "Big Data",
  "Bigotterie",
  "Bikini",
  "Bilderberg-Konferenz",
  "Bilderbuch-Kino",
  "Bildlaufleiste",
  "Bildschirmschoner",
  "Bildtafel",
  "Bildung",
  "Bildungsbürgertum",
  "Bildungsfernsehen",
  "Bilingual",
  "Billard",
  "Bill Gates",
  "Bingo",
  "Binomialkoeffizient",
  "Biodiversität",
  "Biografie",
  "Biologie",
  "Biometrie",
  "Biotic Baking Brigade",
  "Biotonne",
  "Bizone",
  "Bischof",
  "Bisexualität",
  "Bisweilen",
  "Bitcoin",
  "Bittsteller",
  "Blackbox",
  "Black Friday",
  "Black Lives Matter",
  "Blanchieren",
  "Blasphemie",
  "Blattweiser",
  "Blau",
  "Blauer Montag",
  "Bleichmittel",
  "Bleigießen",
  "Bleistiftrock",
  "Blende",
  "Blind Date",
  "Blindenfussball",
  "Blindenhund",
  "Blindenschrift",
  "Blindenstock",
  "Blinder Passagier",
  "Blindgänger",
  "Bliss-Symbol",
  "Blitz-Marathon",
  "Blockupy",
  "Bloder",
  "Blog",
  "Blowback",
  "Blue Marble",
  "Blutdruck",
  "Blutgefäß",
  "Bluthochdruck",
  "BMI",
  "Boatpeople",
  "Bob Marley",
  "Boccia",
  "Bodybuilding",
  "Bodycam",
  "Body Mass Index",
  "Bodypacking",
  "Bohrplattform",
  "Bollenhut",
  "Bonmot",
  "Boston-Marathon",
  "Bot",
  "Boxen",
  "Bonanzarad",
  "Bookcrossing",
  "Book Slam",
  "Boom",
  "Bordell",
  "Borderline-Persönlichkeitsstörung",
  "Boris Palmer",
  "Boss",
  "Botanischer Garten",
  "Botanischer Garten Karlsruhe",
  "Botschaft",
  "Bouldern",
  "Böller",
  "Boykott",
  "Brachialgewalt",
  "Bradley Manning",
  "Brad Pitt",
  "Brailleschrift",
  "Brainstorming",
  "Brandbrief",
  "Brandenburger Tor",
  "Brandmelder",
  "Brandstiftung",
  "Bratwurst",
  "Brauch",
  "Bredouille",
  "Breitensport",
  "Bresche",
  "Brexit",
  "BRICS-Staaten",
  "Briefing",
  "Briefkastenfirma",
  "Briefträgerkissen",
  "Briefwahl",
  "Britney Spears",
  "Brokat",
  "Broschüre",
  "Brötchen",
  "Brotzeit",
  "Browser",
  "Brückentag",
  "Brüder Grimm",
  "Brunch",
  "Bruno Bettelheim",
  "Brüssel",
  "Bruttoinlandsprodukt",
  "Buchführung",
  "Buchpreisbindung",
  "Buchmesse",
  "Buchstaben",
  "Buchstabieren",
  "Buchwiege",
  "Buckingham Palace",
  "Buddelschiff",
  "Buddha",
  "Buddhismus",
  "Bundesamt für Migration und Flüchtlinge",
  "Bundesamt für Verfassungsschutz",
  "Bundesfreiwilligendienst",
  "Bundeskabinett",
  "Bundeskanzler",
  "Bundesland",
  "Bundesminister",
  "Bundesnachrichtendienst",
  "Bundespräsident",
  "Bundespräsidentenwahl 2017",
  "Bundespressekonferenz",
  "Bundesrat",
  "Bundesratspräsident",
  "Bundesregierung",
  "Bundestag",
  "Bundestagsfraktion",
  "Bundestagspräsident",
  "Bundestagswahl",
  "Bundestagswahl 2017",
  "Bundesteilhabegesetz",
  "Bundesverfassungsgericht",
  "Bundesversammlung",
  "Bundesvereinigung Lebenshilfe",
  "Bundeswahlleiter",
  "Bundeswehr",
  "Bundeswehreinsatz in Syrien",
  "Bündnis 90/Die Grünen",
  "Bunga Bunga",
  "Bungeespringen",
  "Bunkobon",
  "Bürger",
  "Bürgeramt",
  "Bürgergeld",
  "Bürgerentscheid",
  "Bürgerinitiative",
  "Bürgerkrieg",
  "Bürgerkrieg in Syrien",
  "Bürgermeister",
  "Bürgerschaftswahl in Bremen 2015",
  "Bürgerschaftswahl in Hamburg 2015",
  "Bürgerversicherung",
  "Bur­ki­ni",
  "Büro",
  "Büro für Leichte Sprache",
  "Burpee",
  "Bushido",
  "Butler",
  "Bud Spencer",
  "Buycott",
  "BYOD",
  "Buzz Aldrin",
  "Cabito",
  "Caffè sospeso",
  "Calisthenics",
  "Call a Bike",
  "Callcenter",
  "Chamäleons",
  "Chicken Tractor",
  "Cameltoe",
  "Candystorm",
  "Cannstatter Wasen",
  "Carl Gustav Jung",
  "Carola Rackete",
  "Carsharing",
  "Castor",
  "Case Management",
  "Catering",
  "CD",
  "CDU",
  "Chalet",
  "Chance",
  "Chancengleichheit",
  "Charles Baudelaire",
  "Charles Bliss",
  "Charles Darwin",
  "Charlie Hebdo",
  "Chanukka",
  "Chaos Computer Club",
  "Chaos Communication Congress",
  "Chat",
  "Checkliste",
  "Checkliste für Leichte Sprache",
  "Cheerleading",
  "Chef",
  "Chefsache",
  "Chemtrail",
  "Chilling effect",
  "Chimäre",
  "China",
  "Chor",
  "Christen",
  "Christian Wulff",
  "Christi Himmelfahrt",
  "Christlich Demokratische Union Deutschlands",
  "Chronisch",
  "Cineastik",
  "Clan",
  "Clark Gable",
  "Claas Relotius",
  "Cluster (in der Wirtschaft)",
  "Cocktailkleid",
  "Coco Chanel",
  "Cognac",
  "Cognac (Branntwein)",
  "Columbusing",
  "Comic",
  "Computer",
  "Computergestützte Übersetzung",
  "Computerprogramm",
  "Computing",
  "Containern",
  "Cooper-Test",
  "Copilot",
  "Copy and paste",
  "Cosplay",
  "Courtney Love",
  "Couch-Potato",
  "Contergan",
  "Corona-Warn-App",
  "COVID-19-Pandemie",
  "Crashpad",
  "Cringe",
  "Croissant",
  "Crowdfunding",
  "Crypto Wars",
  "Crystal Meth",
  "CSU",
  "Cuju",
  "Curling",
  "Currywurst",
  "CyanogenMod",
  "Cyber Monday",
  "Dabbawala",
  "DBT",
  "D-A-CH",
  "Dagger Complex",
  "Dalai Lama",
  "Dalmatiner",
  "Dandy",
  "Das A und O",
  "Das Bildnis des Dorian Gray",
  "Das Blaue Sofa",
  "Das Feuerzeug",
  "Das Gespenst von Canterville",
  "Das hässliche Entlein",
  "Dashcam",
  "Das kalte Herz",
  "Das kleine Mädchen mit den Schwefelhölzern",
  "Das tapfere Schneiderlein",
  "Da steppt der Bär",
  "Das Wort zum Sonntag",
  "Datenschutz-Grundverordnung",
  "DAU",
  "Däumelinchen",
  "Daumenkino",
  "Daumenregister",
  "Deadline",
  "Deakzession",
  "Debatte",
  "Debüt",
  "DE-CIX",
  "Deckname",
  "Deep Learning",
  "Deep Web",
  "Deeskalation",
  "De facto",
  "Defenestration",
  "Definitiv",
  "Deflation",
  "Dekolleté",
  "Dekret",
  "Delfinschwimmen",
  "Dementi",
  "Demenz",
  "Demografie",
  "Demografischer Wandel",
  "Demokratie",
  "Demokratische Partei (Vereinigte Staaten)",
  "Demonstration",
  "Denglisch",
  "Denken",
  "Denkfabrik",
  "Denkmal",
  "Depression",
  "Deutlich",
  "Der Besuch der alten Dame",
  "Der Froschkönig",
  "Der gestiefelte Kater",
  "Der Höllentrip (Film)",
  "Der Krieg der Welten",
  "Der süße Brei",
  "Der Zwerg Nase",
  "Desinfizieren",
  "Des Kaisers neue Kleider",
  "Deutsche Bischofskonferenz",
  "Deutsche Demokratische Republik",
  "Deutsche Pfadfinderschaft Sankt Georg",
  "Deutschland",
  "Deutsche Mark",
  "Deutscher Bundestag",
  "Deutsche Sprache",
  "Dexit",
  "DFB",
  "DGS",
  "Diabetes",
  "Diadem",
  "Diagnose",
  "Diagnostik",
  "Dialog",
  "Dialyse",
  "Diaspora",
  "Diät",
  "Didacta",
  "Diskussion",
  "Die Blumen des Bösen",
  "Die Bremer Stadtmusikanten",
  "Die Geschichte vom Gespensterschiff",
  "Die Grünen",
  "Die Klapperschlange (Film)",
  "Die kleine Meerjungfrau",
  "Die Linke",
  "Dienstleistung",
  "Die Prinzessin auf der Erbse",
  "Die Schneekönigin",
  "Dieter Hildebrandt",
  "Dietrich",
  "Die Zeitmaschine",
  "Digitale Teilhabe",
  "Digital Native",
  "Digital Service Act",
  "Digital Subscriber Line",
  "Digitaluhr",
  "Diktatur",
  "Dildo",
  "Dilettant",
  "Dilma Rousseff",
  "Dingi",
  "Diplomat",
  "Diplomatische Note",
  "Direktmandat",
  "Dirndl",
  "Diskriminierung",
  "Diskurs",
  "Disput",
  "Dissertation",
  "Dito",
  "DLRG",
  "Dokument",
  "Dolby Theatre",
  "Dollar",
  "Dolmetscher",
  "Domain Name System",
  "Dompteur",
  "Donald Duck",
  "Donald Trump",
  "Döner Kebab",
  "Do not feed the trolls",
  "Doping",
  "Doppelgänger",
  "Doppelmoral",
  "Dornröschen",
  "Dosenbier",
  "Dosentelefon",
  "Down Syndrom",
  "Download",
  "Drachenschlucht",
  "Drachensteigen",
  "Dracula",
  "Dragee",
  "Dragqueen",
  "Drakonisch",
  "Draufsicht",
  "Dreamachine",
  "Drehbuch",
  "Drew Barrymore",
  "Dreikönigstreffen (am 6. Januar)",
  "Dressiersack",
  "Dritte Welt",
  "Drive-in",
  "Dr. No-Bikini",
  "Drohne",
  "Drohne (Unbemannte Luftfahrzeuge)",
  "Drucker",
  "Drucker (Beruf)",
  "Druckerei",
  "Drucker (Gerät)",
  "Drucktechnologe",
  "Druckzwiesel",
  "DSL",
  "Duckface",
  "Duden",
  "Dünger",
  "Dunking",
  "Dunning Kruger Effekt",
  "Durcheinander",
  "Dutzend",
  "Duzen",
  "Dysarthrie",
  "Dyskalkulie",
  "Early Adopter",
  "Earth Hour",
  "EASY",
  "E-Bike",
  "Ebola",
  "Ebolafieber",
  "EBook Reader",
  "ECall",
  "Echauffieren",
  "Echelon",
  "Echter Clownfisch",
  "EC-Karte",
  "Eco-Marathon",
  "Edgar Allan Poe",
  "Edward Snowden",
  "Egypt-Air-Flug 181",
  "E-Health",
  "Ehe",
  "Ehegattensplitting",
  "Ehrenbürger",
  "Ehrgeiz",
  "Eichsfeld",
  "Eid",
  "Eierkennzeichnung",
  "Eierlegende Wollmilchsau",
  "Eierwärmer",
  "Eifersucht",
  "Eiffelturm",
  "Eigentum",
  "Eigenverantwortung",
  "Einen Schlussstrich ziehen",
  "Einfältig",
  "Einfaches Leben",
  "Einfühlungsvermögen",
  "Eingabe und Ausgabe",
  "Einkommen",
  "Einkommen aus Besitz",
  "Einladung",
  "Einrad",
  "Eins",
  "Einstein",
  "Einwanderung",
  "Einwanderungsland",
  "Einwohnermeldeamt",
  "Einzugsermächtigung",
  "Eisenach",
  "Eishockey",
  "Eitelkeit",
  "Ekel",
  "Eklat",
  "Elbphilharmonie",
  "E-Learning",
  "Elefantenrunde",
  "Elegisch",
  "Elektrogerät",
  "Elektroschrott",
  "Elfchen",
  "Elisabeth II.",
  "Eliud Kipchoge",
  "Elizabeth Taylor",
  "EloKa",
  "Elon Musk",
  "Eloquenz",
  "El Silbo",
  "Elster",
  "Elster (Software)",
  "Elster (Vogel)",
  "Elterngeld",
  "Elvis Presley",
  "E-Mail",
  "Emma Goldmann",
  "Empathie",
  "Empfang",
  "Empören",
  "Endal",
  "Endlösung der Judenfrage",
  "Endspurt",
  "Energiewende",
  "Engagieren",
  "England",
  "Englische Sprache",
  "Enkeltrick",
  "Enkolpion",
  "Entgelt",
  "Entnazifizierung",
  "Entsafter",
  "Entschädigung",
  "Entwicklungszusammenarbeit",
  "Entzündung",
  "Enzyklopädie",
  "Equal Pay Day",
  "Erdbeben",
  "Erdbeben in Afghanistan 2015",
  "Erdbeben in Iran und Iraq im November 2017",
  "Erdbeben in Nepal 2015 am 25. April 2015",
  "Erdbeben in Taiwan 2016",
  "Erde",
  "Erde (Grund)",
  "Erdöl",
  "Eremit",
  "Ergotherapie",
  "Erich Fromm",
  "Erkrankung",
  "Ernährung",
  "Erneuerbare Energie",
  "Erotik",
  "Erpressung",
  "Erstaufnahmeeinrichtung",
  "Erste Hilfe",
  "Erster Mai",
  "Erster Weltkrieg",
  "Erststimme",
  "Eruieren",
  "Erwachsener",
  "Erwerben",
  "Erwerbstätigkeit",
  "Erwin Schrödinger",
  "Erzieher",
  "Erziehungsgeld",
  "Escape Game",
  "E-Scooter",
  "Eselsohr",
  "Es gibt kein schlechtes Wetter, es gibt nur falsche Kleidung",
  "Esperanto",
  "Espressokanne",
  "Eskalation",
  "Essay",
  "Essen auf Rädern",
  "Esszimmer",
  "E. T. A. Hoffmann",
  "Et cetera",
  "Etepetete",
  "Etwas unter den Teppich kehren",
  "EU-Lateinamerika-Gipfel",
  "Eule",
  "Eunice Kennedy",
  "Eunice Kennedy Shriver",
  "Eunice Shriver",
  "Euphemismus",
  "EU-Rettungsschirm",
  "Euthanasie",
  "Euro",
  "Eurocheque-Karte",
  "Europa",
  "Europahalle Karlsruhe",
  "Europäer",
  "Europahymne",
  "Europäischer Protesttag zur Gleichstellung von Menschen mit Behinderung",
  "Europäischer Rat",
  "Europäische Union",
  "Europäischer Tag der Sprachen",
  "Europäisches Parlament",
  "Europäische Zentralbank",
  "Europa-Park",
  "Europarat",
  "Europatag",
  "Europawahl",
  "Eurovision Song Contest",
  "Evakuierung in Augsburg",
  "Evakuierung in Frankfurt am Main",
  "Evolution",
  "Existenzgründung",
  "Explosion in Tianjin",
  "Exzellent",
  "Experiment",
  "EXPOLINGUA",
  "Extremismus",
  "Fabelwesen",
  "Facebook Safety Check",
  "Facepalm",
  "Fachidiot",
  "Fachkräftemangel",
  "Fachpraktiker",
  "Fachwort",
  "Fahrdienst",
  "Fahreignungsregister",
  "Fahrgemeinschaft",
  "Fairer Handel",
  "Fair Trade",
  "Fake",
  "Faktencheck",
  "Falafel",
  "Fall Emmely",
  "Fallrückzieher",
  "Falludscha",
  "Falschfahrer",
  "Falschmeldung",
  "Familie Lykow",
  "Fanmeile",
  "Fan",
  "Farbe bekennen",
  "Fartons",
  "Faschismus",
  "Fassanstich",
  "Fasten",
  "Fastfood",
  "Fastnacht",
  "Fastnachtssonntag",
  "Fatah",
  "Fatal",
  "Faustregel",
  "Favela",
  "Faye Dunaway",
  "FDP",
  "Federball",
  "Fehlbar",
  "Feiertag",
  "Feilschen",
  "Feindschaft",
  "Feinstaub",
  "Feldjäger",
  "Feminismus",
  "Ferdinand Oechsle",
  "Fernbus",
  "Fernseher",
  "Fernweh",
  "Fesch",
  "Festbeleuchtung",
  "Feuilleton",
  "Fidel Castro",
  "Fieber",
  "FIFA",
  "Filibuster",
  "Finanzieren",
  "Fingerhakeln",
  "Fingerspitzengefühl",
  "Finnland",
  "Fipronil Skandal 2017",
  "Fisch",
  "Fiskalpakt",
  "Fiskus",
  "Fitness",
  "Fix und fertig",
  "FKK",
  "Flachmann",
  "Flammkuchen",
  "Flammkuchen (Rezept)",
  "Fappening",
  "Flaschenpost",
  "Flashmob",
  "Flexibeam",
  "Flexitarismus",
  "Flipchart",
  "Flip Flops",
  "Flohmarkt",
  "Flohzirkus",
  "FLOPS",
  "Flöte",
  "Flucht",
  "Flucht über das Mittelmeer in die EU",
  "Flugbegleiter",
  "Flug 752",
  "Flug 8501",
  "Flug 9525",
  "Flug GE235",
  "Flug IL 267",
  "Flug KGL9268",
  "Flug MH 17",
  "Flug MH 370",
  "Flüchtling",
  "Flüchtlingsboot-Havarie im Dezember 2014",
  "Flüchtlingskrise 2015",
  "Flüchtlingskrise in Deutschland 2015",
  "Flüchtlingslager",
  "Flüchtlingsstrom",
  "Flüstern",
  "Flyer",
  "Föderalismus",
  "Football Leaks",
  "Fordern",
  "Forderung",
  "Förster",
  "Fotokamera",
  "Fötus",
  "Foppen",
  "Forcieren",
  "Foul",
  "Fracking",
  "Fraktion",
  "Frau",
  "Frauenbild",
  "Frauentaxi",
  "Frau Holle",
  "Fräulein",
  "Frankfurter Buchmesse",
  "Frankreich",
  "Frank-Walter Steinmeier",
  "Franz Kafka",
  "Frauenarzt",
  "Frauenquote",
  "Frauentag",
  "Frauentragen",
  "Freedom Toaster",
  "Free Hugs",
  "Free Solo",
  "Freie Demokratische Partei",
  "Freie Inhalte",
  "Freier",
  "Freie Software",
  "Freihandelsabkommen",
  "Freiheitsstatue",
  "Freiwillige Selbstkontrolle der Filmwirtschaft",
  "Freizeit",
  "Freizeitsport",
  "Fremdeln",
  "Fremdenfeindlichkeit",
  "Fremdenzimmer",
  "Fremdschämen",
  "Fremdsprache",
  "Fremdwort",
  "Freunde Syriens",
  "Freundschaft",
  "Freundschaftsband",
  "Frida (Hund)",
  "Fridays For Future",
  "Friedrich Dürrenmatt",
  "Frisur",
  "Frisuren",
  "Fröbelstern",
  "Frohes neues Jahr",
  "Fronleichnam",
  "Frontflip",
  "Frühjahrsmüdigkeit",
  "Frühlingsanfang",
  "Frühschoppen",
  "Fuchsschwanz",
  "Fuchsschwanz (Säge)",
  "Fukushima",
  "Führerschein",
  "Führungszeugnis",
  "Füllstrich",
  "Füllwort",
  "Fünfprozentklausel",
  "Funktionär",
  "Fürderhin",
  "Fussball",
  "Fußball-Europa-Meisterschaft 2016",
  "Fußball-Europa-Meisterschaft 2020",
  "Fußball Weltmeisterschaft 2014",
  "Fußball Weltmeisterschaft 2015",
  "Fußball Weltmeisterschaft 2019",
  "Futterdummy",
  "G20-Gipfel in Hamburg 2017",
  "G7",
  "G7-Gipfel auf Schloss Elmau 2015",
  "G8",
  "G10-Gesetz",
  "Gadget",
  "Ghostwriter",
  "Galileo Galilei",
  "Gallenblase",
  "Gal Gadot",
  "Gamescom",
  "Gammelfleisch",
  "Gänsehaut",
  "Gated Community",
  "Gaokao",
  "Gaskocher",
  "Gazastreifen",
  "Gazette",
  "GCHQ",
  "Gebärden",
  "Gebärdendolmetscher",
  "Gebärdensprache",
  "Gebäude",
  "Gebirge",
  "Gebührenbescheid",
  "Geburtstag",
  "Gedächtnis",
  "Gedankenübertragung",
  "Gedankenverbrechen",
  "Gefahrzeichen",
  "Gefängnis",
  "Gegebenenfalls",
  "Geheimzahl",
  "Gehirn",
  "Gehörlos",
  "Geisel",
  "Geisha",
  "Geisterspiel",
  "Geisterstadt",
  "Geisteswissenschaft",
  "Geistiges Eigentum",
  "Gelb",
  "Gelbwesten",
  "Geld",
  "Geldausgabeautomat",
  "Geldbuße",
  "Geldkarte",
  "Geldwäsche",
  "Geldwert",
  "Geltungssucht",
  "Gemeinde",
  "Gemeinderat (in Deutschland)",
  "Gemeinfreiheit",
  "Gemeinwesen",
  "Geminiden",
  "Generalsekretär",
  "Generalversammlung der Vereinten Nationen",
  "Generation X",
  "Generation Y",
  "Generikum",
  "Gene Roddenberr (Seite nicht vorhanden)",
  "Genmais",
  "Genossenschaft",
  "Genozid",
  "Gentrifizierung",
  "Genuin",
  "Geocaching",
  "Geophysik",
  "George Orwell",
  "George Washington",
  "Geothermie",
  "Gerade und Ungerade",
  "Gericht",
  "Gerücht",
  "Geschäftsfähigkeit",
  "Geschichte (Wort·klärung)",
  "Geschirrspülmaschine",
  "Geschlechtskrankheit",
  "Geschmacklos",
  "Gesellig",
  "Gesellschaft",
  "Gesetz",
  "Gesetzloser",
  "Gesetz (Natur)",
  "Gesetz (Politik)",
  "Gesetz über die Zeitbestimmung",
  "Gesetz zur Gleichstellung behinderter Menschen",
  "Gesicht",
  "Gestapo",
  "Gesundheitspolitik",
  "Getränk",
  "Getreide",
  "Gewaltenteilung",
  "Gewalt in Chemnitz 2018",
  "Gewächshaus",
  "Gewerkschaft",
  "Gewichtheben Gewichtsklassen",
  "Gewichtmachen",
  "Gewissen",
  "Gewitter",
  "Gewöhnlicher Feldsalat",
  "GEZ",
  "Gipfeltreffen",
  "Giftgas",
  "Giorgio Chiellini",
  "Girokonto",
  "Glatze",
  "Gläubiger",
  "Gleichbehandlungsgesetz",
  "Gleichberechtigung",
  "Gleichstellung",
  "Globalisierung",
  "Glück",
  "Glücksatlas",
  "Glückskeks",
  "Glückspilz",
  "Goalball",
  "Godwins Gesetz",
  "Goldener Windbeutel",
  "Google",
  "Google Glass",
  "Google Maps",
  "Google Person Finder",
  "GoPro",
  "Gott",
  "Graffiti",
  "Grandios",
  "Graphotherapie",
  "Grätsche (Fußball)",
  "Grazil",
  "Gregor Gysi",
  "Grenzkontrolle",
  "Greta Thunberg",
  "Gretchenfrage",
  "Grexit",
  "Greyfriars Bobby",
  "Griechenland",
  "Griechische Staatsschuldenkrise",
  "Griechisches Referendum 2015",
  "Großbritannien",
  "Große Koalition",
  "Großraumbüro",
  "Großwetterlage",
  "Groot",
  "Groufie",
  "Ground Zero",
  "Grubenunglück von Soma",
  "Grumpy Cat",
  "Grün",
  "Grundgesetz für Deutschland",
  "Gründonnerstag",
  "Grundrechte (von Deutschland)",
  "Grundrente",
  "Grundwasser",
  "Grüne Dame",
  "Grüne Politik",
  "Grüne Route",
  "Grünlandumbruch",
  "Gruppe der zwanzig wichtigsten Industrie- und Schwellenländer",
  "GSG9",
  "GSM",
  "Guatemala Bergsturz 2015",
  "Guerilla Knitting",
  "Güllegürtel",
  "Günter Ammon",
  "Gut Ei und Kikeriki",
  "Guten Rutsch",
  "Guter Vorsatz",
  "Gutmensch",
  "Gymnasium",
  "Gynäkologe",
  "Haargummi",
  "Haartrockner",
  "Habemus papam",
  "Hachiko",
  "Hackenporsche",
  "Hacker",
  "Hackerspace",
  "Hades",
  "Hadsch",
  "Hagen Liebing",
  "Hagia Sophia",
  "Halbwissen",
  "Halfpipe",
  "Halloween",
  "Halloween - Die Nacht des Grauens",
  "Hals über Kopf",
  "Hamas",
  "Hamburger-Menü",
  "Hammelsprung",
  "Handball",
  "Handbike",
  "Händewaschen",
  "Handgepäck",
  "Handlanger",
  "Handlauf",
  "Handpuppe",
  "Handschuhfach",
  "Handstand",
  "Handy",
  "Hannah Arendt",
  "Hans Asperger",
  "Hans Christian Andersen",
  "Hansdampf in allen Gassen",
  "Hantel",
  "Harald Juhnke",
  "Hardliner",
  "Hass",
  "Hassrede",
  "Hausarrest",
  "Haushuhn",
  "Hausmannskost",
  "Hausratversicherung",
  "Hausrind",
  "Haustier",
  "Hawaiianisch",
  "Hawaii-Toast",
  "HDTV",
  "Hebräisch",
  "Hedonismus",
  "Hefe",
  "Heiko Maas",
  "Heilerziehungspfleger",
  "Heilige Drei Könige",
  "Heimat",
  "Heimbeirat",
  "Heimmitwirkungsverordnung",
  "HeimmwV",
  "Heimvertrag",
  "Heimwegtelefon",
  "Heinrich Hübsch",
  "Heinz Erhardt",
  "Heirat",
  "Helene Fischer",
  "Helene Weigel",
  "Helikopter-Eltern",
  "Helmut Kohl",
  "Henkelmann",
  "Henry Miller",
  "Hera",
  "Herbert Marcuse",
  "Hermann Gröhe",
  "Hermann Rorschach",
  "Herr der Fliegen",
  "Herrengedeck",
  "Hessen",
  "Hestia",
  "H.G.Wells",
  "High Heel",
  "High Society",
  "Hieroglyphen",
  "Hikikomori",
  "Hillary Clinton",
  "Hillbilly",
  "Himbeergeist",
  "Hirnschädigung",
  "HitchBot",
  "Hitzefrei",
  "Hitzewelle",
  "Hitzewelle 2015",
  "HIV",
  "HI-Virus",
  "Hilfsmittel",
  "Himmelfahrtskommando",
  "Hinterbänkler",
  "Hinter dem Mond leben",
  "Hinterfragen",
  "Hiobsbotschaft",
  "Hisbollah",
  "Hoax",
  "Hochaltrigkeit",
  "Hochladen",
  "Hochrechnung",
  "Hochschlafen",
  "Hochsitz",
  "Hochwasser",
  "Hofieren",
  "Höflichkeit",
  "Holocaust",
  "Holozän",
  "Holzmedien",
  "Homo-Ehe",
  "Homosexuell",
  "Hooligan",
  "Holländische Krankheit",
  "Hollywood Sign",
  "Hollywood Walk of Fame",
  "Hongkong",
  "Hörfilm",
  "Hörstörung",
  "Horst Seehofer",
  "Hostess",
  "Hotel Anschlag in Bamako 2015",
  "Hotelbus",
  "Hotline",
  "Hot Swap",
  "House-Running",
  "HR Giger",
  "Hubert Hüppe",
  "Hugo Gernsback",
  "Hütchenspiel",
  "Humbug",
  "Hund",
  "Hundegesetze",
  "Hundekommandos",
  "Hundstage",
  "Hunds­fott",
  "Hunger",
  "Hungersnot",
  "Hungerstreik",
  "Hupkonzert",
  "Hurrakify",
  "Hurrikan",
  "Hybrid-Auto",
  "Hybris",
  "Hygiene",
  "Hyperlink",
  "IAA",
  "Ian Fleming",
  "Ice Bucket Challenge",
  "Ich fress einen Besen",
  "ICQ",
  "Idee",
  "IG Metall",
  "Ilja Seifert",
  "IMHO",
  "Immanuel Kant",
  "Immens",
  "Immunität (in der Medizin)",
  "Immunsystem",
  "Imperialismus",
  "Improvisation",
  "Impfung",
  "In den April schicken",
  "In den Arsch kriechen",
  "Indien",
  "Individuell",
  "Indiz",
  "Industrie",
  "Industrielle Revolution",
  "Infantil",
  "In flagranti",
  "Inflation",
  "Influencer",
  "Informatik",
  "Information",
  "Informationsfreiheitsgesetz",
  "Ingenuity",
  "Inklusion",
  "Inklusionssport",
  "Innenpolitik",
  "Innovation",
  "Incel",
  "Inclusion Europe",
  "Insekt",
  "In spe",
  "Instagram",
  "Instant Messaging",
  "Integration",
  "Integrationsgesetz",
  "Integrationshotel",
  "Interdisziplinär",
  "Internationale Raumstation",
  "Internationale Standardbuchnummer",
  "Internationaler Frauentag",
  "Internationaler Hände-Waschtag",
  "Internationaler Hurentag",
  "Internationaler Katzentag",
  "Internationaler Mädchentag",
  "Internationaler Tag der Kinderrechte",
  "Internationaler Tag der Menschen mit Behinderung",
  "Internationaler Tag der Migranten",
  "Internationaler Tag des Kusses",
  "Internationaler Tag der Pressefreiheit",
  "Internationaler Tag der spanischen Sprache",
  "Internationaler Tag zur Beseitigung von Gewalt gegen Frauen",
  "Internet",
  "Internetausdrucker",
  "Internet der Dinge",
  "Internetwache",
  "Internist",
  "InterRail",
  "Intervention",
  "Intrige",
  "Inzidenz",
  "Ionosphäreninstitut",
  "IPhone",
  "IPod",
  "Irene Pepperberg",
  "Irish Pub",
  "Irokesenschnitt",
  "Iron Dome",
  "Irreversibel",
  "Isla del Perejil",
  "Islam",
  "Islamischer Staat (Gruppe)",
  "Isolationstank",
  "Israel",
  "Italien",
  "Italienische Sprache",
  "Jadehase",
  "J4f",
  "Jahr",
  "Jahreszeit",
  "22. Jahrhundert",
  "Jahwe",
  "Jaki",
  "Jakobsweg",
  "Jamaika-Koalition",
  "Jamaika-Sondierungen",
  "Japan",
  "Jargon",
  "JAWS",
  "Jaywalking",
  "Jeepney",
  "Jeff Bezos",
  "Jemanden über den Tisch ziehen",
  "Jesid",
  "Jesus",
  "Jeton",
  "Jiddisch",
  "Jim Jarmusch",
  "Joachim Gauck",
  "Joachim Löw",
  "Joe Biden",
  "Joe Dante",
  "Jogging",
  "Johannes Kepler",
  "Johann Heinrich Pestalozzi",
  "John Carpenter",
  "John Cunningham Lilly",
  "John Steinbeck",
  "Jonglieren",
  "José Mujica",
  "Josefs Grab",
  "Joseph Weizenbaum",
  "Juden",
  "Judentum",
  "Judo Gewichtsklassen",
  "Jukebox",
  "Jules Verne",
  "Jupiter",
  "Jupiter (Planet)",
  "Jura",
  "Juri Gagarin",
  "Jurist",
  "Just",
  "Justin Bieber",
  "Jo-Jo",
  "Jo-Jo-Effekt",
  "Journalist",
  "Joystick",
  "Kabelanschluss",
  "Kabeln",
  "Kabinett",
  "Kabinett Merkel 4",
  "Kadaver",
  "Kaffeefahrt",
  "Kaffeepads",
  "Kafkaesk",
  "Kaiser",
  "Kaiserschnitt",
  "Kake",
  "Kakophonie",
  "Kalaschnikow",
  "Kalaschnikow Maschinengewehr",
  "Kalauer",
  "Kallikratis-Programm",
  "Kalorien",
  "Kalte Küche",
  "Kalte Progression",
  "Kalter Krieg",
  "Kaltmiete",
  "Kamelle",
  "Kampfkunst",
  "Kampfsport",
  "Kanada",
  "Kanalisation",
  "Kandidat",
  "Kandieren",
  "Kanzlerkandidat",
  "Kapselhotel",
  "Kapuzenpullover",
  "Karaffe",
  "Kardinal",
  "Karenzzeit",
  "Karfreitag",
  "Karikatur",
  "Karin Evers-Meyer",
  "Karl Koch (Hacker)",
  "Karl Marx",
  "Karl May",
  "Karneval in Rio",
  "Karsamstag",
  "Karg",
  "Karzinom",
  "Kaspar Hauser",
  "Katas",
  "Katastrophenschutz",
  "Katharine Hepburn",
  "Käthe Kollwitz",
  "Katrin Göring-Eckardt",
  "Katze",
  "Kauderwelsch",
  "Kavallerie",
  "Kerngehäuse",
  "Kettenbrief",
  "Kettenstemmer",
  "Kewl",
  "Kibbuz",
  "Kiloware",
  "Kilt",
  "Kim Jong-un",
  "Kimono",
  "Kind",
  "Kindergrundsicherung",
  "Kinderlähmung",
  "Kindermädchen",
  "Kindersprache",
  "Kindertagesstätte",
  "Kindle Fire",
  "Kino",
  "Kiosk",
  "Kippa",
  "Kirre",
  "Kirsch-Kern-Weitspucken",
  "Kirsten Bruhn",
  "Kissen",
  "KISS-Prinzip",
  "Kitsch",
  "Kitt­chen",
  "Klamm",
  "Klamm (Adjektiv)",
  "Klammergriff",
  "Klamm (Substantiv)",
  "Klapprad",
  "Klassenraum",
  "Klausel",
  "Klausur",
  "Kleidernetz",
  "Kleinbürger",
  "Kleine Anfrage",
  "Kleiner Panda",
  "Kleines Schwarzes",
  "Kleinkind",
  "Kleinlaut",
  "Klettern",
  "Klima",
  "Klimakrise",
  "Klimawandel",
  "Klimmzug",
  "Klingelstreich",
  "Knallbonbon",
  "Knickerbocker",
  "Knight Rider",
  "Knock Out",
  "Knor­ke",
  "Know How",
  "Koalition (in der Politik)",
  "Koalitionsvertrag",
  "Koberer",
  "Kochbuch",
  "Kochrezept",
  "Kofferwort",
  "Kognition",
  "Kohl",
  "Kohlenstoffdioxid",
  "Kollektiv·vertrag",
  "Kollidieren",
  "Kölsch",
  "Kölsch (Sprache)",
  "Komfortverhalten",
  "Kommilitone",
  "Kommissbrot",
  "Kommunalwahl",
  "Kommunikation",
  "Kommunikationsdirektor",
  "Kommunismus",
  "Komponist",
  "Kondition",
  "Kondolenz",
  "Kondom",
  "Kondomautomat",
  "Konflikt",
  "Konfus",
  "Königsteiner Schlüssel",
  "Konjunktiv",
  "Konjunktur",
  "Kon­kla­ve",
  "Konrad Duden",
  "Konrad Koch",
  "Konrad Zuse",
  "Konstatieren",
  "Konsonant",
  "Konsum",
  "Kontaminieren",
  "Konterfei",
  "Kontinent",
  "Konstruktiv",
  "Kontroverse",
  "Kontorist",
  "Kontostand",
  "Konsultation",
  "Konvention",
  "Konvention der Vereinten Nationen über die Rechte von Menschen mit Behinderungen",
  "Konversation",
  "Konvoi",
  "Konzentrationslager",
  "Konzil",
  "Koordinator für die zwischengesellschaftliche Zusammenarbeit mit Russland, Zentralasien und den Ländern der Östlichen Partnerschaft",
  "Kopfballtischtennis",
  "Kopfgeld",
  "Kopfsalat",
  "Koprolalie",
  "Körperbemalung",
  "Körpergewicht",
  "Korea-Konflikt",
  "Korruption",
  "Korruption in der FIFA",
  "Koryphäe",
  "Kosewort",
  "Kosmopolitismus",
  "Kraftknoten",
  "Krafttraining",
  "Krankenkasse",
  "Krankenversicherung",
  "Krankenversicherungskarte",
  "Krankheit",
  "Krass",
  "Krawatte",
  "Kredenzen",
  "Kredit",
  "Kreisfreie Stadt",
  "Krempe",
  "Krieg",
  "Krimkrise",
  "Krise",
  "Kristallnacht",
  "Kriterium",
  "Kriterien",
  "Kryptomnesie",
  "Kryptowährung",
  "Kugelgrill",
  "Kulanz",
  "Kultur",
  "Kulturbeutel",
  "Kultusminister",
  "Kurzarbeit",
  "Kurz-URL-Dienst",
  "Kung Fu",
  "Kunst",
  "Kunstfund in München",
  "Künstliche Intelligenz",
  "Kunstmärchen",
  "Kunstwort",
  "Kurd Laßwitz",
  "Kurschatten",
  "Kurt Cobain",
  "Kurzfilm",
  "Kurzweilig",
  "Kuschen",
  "Kyoto Protokoll",
  "Lästig",
  "Labern",
  "Labor",
  "Laboratorium",
  "Lackmustest",
  "Ladenhüter",
  "Lady Gaga",
  "Lady Godiva",
  "Laika",
  "Lakonisch",
  "Lallen",
  "Lallwort",
  "Lama (Kamel)",
  "Lametta",
  "Lampedusa",
  "Lampenfieber",
  "Landesparlament",
  "Landesregierung",
  "Landesverrat",
  "Landrat",
  "Landsleute",
  "Landtagswahl",
  "Landtagswahl in Bayern 2018",
  "Landtagswahl in Hessen 2018",
  "Übung: Landtagswahl in Nordrhein-Westfalen 2017",
  "Landtagswahl in Rheinland-Pfalz 2016",
  "Landtagswahl im Saarland 2017",
  "Landyachting",
  "Lange Nase",
  "Langeweile",
  "LanguageTool",
  "LAN Party",
  "Lapdance",
  "Lapidar",
  "Laptop",
  "Lara Croft",
  "Larifari",
  "Laryngektomie",
  "Lastenfahrrad",
  "Late Bloomer",
  "Late Talker",
  "Latein",
  "Lateinformation",
  "Lateinische Sprüche",
  "Latent",
  "Lau",
  "Laudatio",
  "Läuferhoch",
  "Lausbub",
  "Laut",
  "Lazarett",
  "Learning by Doing",
  "Lebensqualität",
  "Legalität",
  "Legasthenie",
  "Leggins",
  "Legislative",
  "Lehnwort",
  "Lehrer",
  "Leichnam",
  "Leichte Sprache",
  "Leichte Sprache App",
  "Leichte Sprache Regeln",
  "Leistungskurs",
  "Leitartikel",
  "Lektor",
  "Lemmy Kilmister",
  "Leonard Nimoy",
  "Lernen",
  "Lernkartei",
  "L’Eroica",
  "Lesbisch",
  "Leseexemplar",
  "Lesekasten",
  "Lesen",
  "Leseratte",
  "Leserechtschreibstörung",
  "Leserlichkeit",
  "Lesezirkel",
  "Letzte",
  "Leugnen",
  "Lewis Carroll",
  "Lexikografie",
  "Lexikon",
  "Lifehack",
  "Lifter",
  "Light it up blue",
  "Liebe",
  "Liebesschloss",
  "Liebestöter",
  "Liebkosen",
  "Liedtext",
  "Linkshändertag",
  "Limbo",
  "Limor Fried",
  "Lingua franca",
  "Linus Torvalds",
  "Linux",
  "Lippen-Kiefer-Gaumen-Segel-Fehlbildung",
  "Lippenlesen",
  "Lippenstift",
  "Lippstadt",
  "Literatur",
  "Litfaßsäule",
  "Little Italy (New York City)",
  "Lobby",
  "Lobby (Politik)",
  "Locher",
  "Lochzange",
  "Loipe",
  "Lockpicking",
  "Logopädie",
  "Lol",
  "London",
  "Los Angeles",
  "Lösungsbuch",
  "Louis Braille",
  "Loukanikos",
  "LSD",
  "Luddismus",
  "Ludwig Lugmeier",
  "Ludwik Lejzer Zamenhof",
  "Luft",
  "Luftmatratze",
  "Luftpolsterfolie",
  "Lügenpresse",
  "Lupe",
  "Lurker",
  "Mac",
  "Macht",
  "Machthaber",
  "Mädchenname",
  "Macke",
  "Madonna",
  "Magenknurren",
  "Maghreb",
  "Magnus Carlsen",
  "Mahatma Gandhi",
  "Mail",
  "Mail (Apple)",
  "Mainstream",
  "Mais",
  "Majdan Nesaleschnosti",
  "Majestätsbeleidigung",
  "Majolika Manufaktur",
  "Maker Faire",
  "Malala Yousafzai",
  "Maliziös",
  "Maloche",
  "Mandat (in der Politik)",
  "Manga",
  "Mängelexemplar",
  "Manhattanhenge",
  "Manhattan-Projekt",
  "Mann",
  "Mann mit dem Hammer",
  "Manuelle Therapie",
  "Mär",
  "Märchen",
  "Maria Sharapova",
  "Marie Curie",
  "Marie Pappenheim",
  "Marissa Mayer",
  "Mark Shuttleworth",
  "Markt",
  "Marktschreier",
  "Mark Twain",
  "Marktwirtschaft",
  "Markus Kurth",
  "Mark Zuckerberg",
  "Marshallplan",
  "Mars (Planet)",
  "Martialisch",
  "Martin Luther King",
  "Martin Schulz",
  "Martin von Tours",
  "Martinstag",
  "Martinshorn",
  "Marxismus",
  "Mary Leakey",
  "Massaker",
  "Massenmedien",
  "Massenpanik in Mekka 2015",
  "Massentierhaltung",
  "Massenvernichtungswaffen",
  "Massive Open Online Course",
  "Maß (Getränk)",
  "Maßschneider",
  "Mauerblümchen",
  "Mauerfall",
  "Maulheld",
  "Maut",
  "Mautpreller",
  "Max Horkheimer",
  "Maya-Schrift",
  "McDonald’s",
  "McJob",
  "Medikament",
  "Medio·punkt",
  "Medizin",
  "Meerjungfrau",
  "Mehl",
  "Mehraufwandsentschädigung",
  "Mehrarbeit",
  "Mehrdeutigkeit",
  "Mehrgenerationshaus",
  "Mehrmenge",
  "Mehrsprachigkeit",
  "Mehrwertsteuer",
  "Melancholie",
  "Meldung",
  "Membrane",
  "Mensa",
  "Mensch",
  "Menschenrechte",
  "Menschenschmuggel",
  "Menschenwürde",
  "Mensch sein in Österreich",
  "Mensch Zuerst",
  "Menstruation",
  "Merchandising",
  "Merkbefreiung",
  "Merkel",
  "Merkel-Raute",
  "Merkspruch",
  "Meryl Streep",
  "Meschugge",
  "Mesozoikum",
  "Messenger",
  "Metadaten",
  "Meteorit",
  "Mettigel",
  "Mexiko",
  "Michael Schuhmacher",
  "Michael Jackson",
  "Michail Borissowitsch Chodorkowski",
  "Michail Kalaschnikow",
  "Micky Maus",
  "Microadventure",
  "Microsoft",
  "Microsoft Office",
  "Microsoft Word",
  "Mietpreisbremse",
  "Migrant",
  "Migration",
  "Migrationspakt",
  "Mijotieren",
  "Mikrofon",
  "Milchstraße",
  "Miley Cyrus",
  "Millennium",
  "Mimik",
  "Minarett",
  "Minderjährig",
  "Mindestpreis",
  "Mindestlohn",
  "Mindestkriterien",
  "Minestrone",
  "Mini-Job",
  "Minirock",
  "Minister",
  "Ministererlaubnis",
  "Ministerpräsident",
  "Mint",
  "Mint (Abkürzung)",
  "MINT",
  "Misandrie",
  "Miroslav Klose",
  "Mitbringsel",
  "Mitfahrgelegenheit",
  "Mitfahrzentrale",
  "Mitgift",
  "Mitgliedervotum der SPD für den Koalitionsvertrag 2018",
  "Mittelschicht",
  "Mobiltelefon",
  "Mobilheim",
  "Modem",
  "Modenschau",
  "Moderne Dichtung",
  "Modewort",
  "Modell",
  "Modellregion",
  "Modellregionen",
  "Mogelpackung",
  "Mohammed",
  "Mohammed Hussein Tantawi",
  "Molfetta",
  "Molotowcocktail",
  "Monarchie",
  "Mondfinsternis",
  "Monowi",
  "Monty Python",
  "Monokini",
  "Monolog",
  "Monopol",
  "Monopoly",
  "Monoski",
  "Moonalphabet",
  "Mooning",
  "Morgenland",
  "Morgenrot",
  "Mörser",
  "Moschee",
  "Motorrad",
  "Mozilla Firefox",
  "MS Word",
  "Muckefuck",
  "Mufti",
  "Muhammad Ali",
  "Müllabfuhr",
  "Münchner Sicherheitskonferenz",
  "Mundraub",
  "Mundraub (Internetseite)",
  "Münzbrett",
  "Münzschieber",
  "Münztelefon",
  "Musik",
  "Muskeln",
  "Muslim",
  "Müsliriegel",
  "Muttersprache",
  "Mutismus",
  "Mysteriös",
  "Nachbar",
  "Nachbarschaftshaus",
  "Nachhaltigkeit",
  "Nachkriegszeit nach dem Zweiten Weltkrieg in Deutschland",
  "Nachricht",
  "Nachrichten",
  "Nachrichtendienst",
  "Nachrichtendienst (Geheimdienst)",
  "Nachruf",
  "Nacktscanner",
  "Nadeschda Tolokonnikowa",
  "Nahostkonflikt",
  "Nahrung",
  "Nahrungsmittel",
  "Narkose",
  "NASA",
  "Nasenbohren",
  "Nasenspülung",
  "Nassauer",
  "Nationalfeiertag",
  "Nationalratswahl",
  "Nationalsozialismus",
  "Nationalsozialistischer Untergrund",
  "NATO",
  "Naturgesetz",
  "Naturschutzgebiet",
  "Navi",
  "Navigationsgerät",
  "Nazi",
  "Nebel des Krieges",
  "Nebelkerze",
  "Nebentätigkeit",
  "Nebenwirkung",
  "Neid",
  "Neil Armstrong",
  "Neknomination",
  "Nelson Mandela",
  "Neoliberalismus",
  "Neologismus",
  "Nerd",
  "Nerven",
  "Nervenkitzel",
  "Nesthäkchen",
  "Netbook",
  "Netflix",
  "Netiquette",
  "Netzbürger",
  "Netzkultur",
  "Netzneutralität",
  "Netzpolitik",
  "Netzwerkdurchsetzungsgesetz",
  "Neubau der Europäischen Zentralbank",
  "Neugeborenes",
  "Neue Klassenpolitik",
  "Neujahr",
  "Neujahrsansprache",
  "Neurologe",
  "Neuwahlen",
  "Newbie",
  "Newsletter",
  "Nichtstuer",
  "Nichtsnutz",
  "Nichtwähler",
  "Nickname",
  "Niederlage",
  "Niederlande",
  "Niemandsland",
  "Niere",
  "Nikab",
  "Nike Mag",
  "Nikolaus",
  "Nikolaus Kopernikus",
  "Nikolaustag",
  "Nils Holgersson",
  "No-go-Area",
  "Nomophobie",
  "Nordamerika",
  "Nord Stream",
  "Normalzeit",
  "Norman Atlantic",
  "North Atlantic Treaty Organization",
  "Norwegen",
  "Notausgang",
  "Notebook",
  "Notorisch",
  "Notruf",
  "Notting-Hill-Karneval",
  "Novemberrevolution",
  "Novize",
  "Novum",
  "NSA",
  "Nudelholz",
  "Null Bock Generation",
  "Nulltoleranzstrategie",
  "Nunmehr",
  "Nürnberger Gesetze",
  "Nürnberger Trichter",
  "Nutztier",
  "Obduktion",
  "Oberbürgermeister",
  "Obmann",
  "Obskur",
  "Obsoleszenz",
  "Obsolet",
  "Obstsalat",
  "Offenbar",
  "Öffentlicher Rundfunk",
  "Öffentliches Gut",
  "Ohr",
  "Oktoberfest",
  "Ö3",
  "Ökumene",
  "Olaf Scholz",
  "Old School",
  "Olivenöl",
  "Olymp",
  "Olympische Spiele",
  "One-Click",
  "Online Banking",
  "Onomatopoesie",
  "OPEC",
  "Open Access",
  "Open Educational Resources",
  "Open Office",
  "Opera",
  "Opposition",
  "Orangenhaut",
  "Organ",
  "Organ (Biologie)",
  "Organisation",
  "Organisieren",
  "Organisierte Kriminalität",
  "Organspende",
  "Orgasmus",
  "Origami",
  "Orkan Niklas",
  "Orthodox",
  "Orthographie",
  "Oscar",
  "Oscar Wilde",
  "Oskar Schindler",
  "Österreich",
  "Ostern",
  "Osternacht",
  "Ostersonntag",
  "OSZE",
  "Otto von Bismarck",
  "Outtake",
  "OS X",
  "Overdressed",
  "Overknees",
  "Pablo Picasso",
  "Pablo Pineda",
  "Packungsbeilage",
  "Pailletten",
  "Paintball",
  "Palindrom",
  "Palmsonntag",
  "Panama Papers",
  "Pandemie",
  "Panopticon",
  "Papagei Alex",
  "Paparazzo",
  "Papierabfall",
  "Papierflieger",
  "Papier ist geduldig",
  "Papst",
  "Papst Franziskus",
  "Paradigmenwechsel",
  "Paradoxon",
  "Pariahund",
  "Paradise Papers",
  "Paragraph",
  "Paralexie",
  "Paralympics",
  "Paralympics Boccia",
  "Paralympics Bogenschießen",
  "Paralympics Gewichtheben",
  "Paralympics Judo",
  "Paralympics Klassifizierung (Schwimmen)",
  "Paralympics Radsport",
  "Paralympics Rudern",
  "Paralympics Schwimmen",
  "Paralympics Segeln",
  "Paralympics Tischtennis",
  "Pareidolie",
  "Paretoprinzip",
  "Parkinson",
  "Parkraumbewirtschaftungszone",
  "Parlament",
  "Parlamentarischer Beirat für nachhaltige Entwicklung",
  "Partei",
  "Parteigründung",
  "Parteivorsitzender",
  "Par­tei­zen­t­ra­le",
  "Partizipation",
  "Party-Service",
  "Passives Wissen",
  "Pasteurisierung",
  "Pathos",
  "Patient",
  "Patio",
  "Patzer",
  "Paul Allen",
  "PayPal",
  "Pazifismus",
  "PC",
  "Pedant",
  "Pedometer",
  "Peer Counselling",
  "PEGIDA",
  "Pelzfarm",
  "Penibel",
  "Penis",
  "People First",
  "Pep Guardiola",
  "Perfide",
  "Persiflage",
  "Personenstandsregister",
  "Person of the Year",
  "Perücke",
  "Pesach",
  "Peschmerga",
  "Persönliches Budget",
  "Petition",
  "Petitionsausschuss",
  "Pet Rock",
  "Pfandhaus",
  "Pfannkuchen (Rezept)",
  "Pfen­nig­fuch­ser",
  "Pfingsten",
  "Pflanze",
  "Pflegenotstand",
  "Phantomdiskussion",
  "Phantomtor",
  "Philanthropie",
  "Philippinen",
  "Philosoph",
  "Photobombing",
  "Photoshop",
  "Photosynthese",
  "Physik",
  "Physikalisches Gesetz",
  "Physiotherapie",
  "Piano",
  "Pidgin-Sprachen",
  "Piefke",
  "Piercing",
  "Pietät",
  "Pig Latin",
  "Pilger",
  "Pille",
  "Pille danach",
  "Pille (Fußball)",
  "Pille (Medikament)",
  "Pilsdeckchen",
  "Pilstulpe",
  "Pi mal Daumen",
  "Pink Tax",
  "Pin-up",
  "Piratenpartei Deutschland",
  "Pirouette",
  "Pizza",
  "Pizzagate",
  "Placebo",
  "Plakatreiter",
  "Plakatwand",
  "Planking",
  "Plansprache",
  "Platmenage",
  "Plattenbau",
  "Plattitüde",
  "Platzverweis",
  "Plausch",
  "Plausibel",
  "Playstation Portable",
  "Plenarsaal",
  "Plogging",
  "Plural",
  "P+M-Parkplatz",
  "Podstakannik",
  "Poker Flat Research Range",
  "Polarisierung (in der Politik)",
  "Polen",
  "Polio",
  "Politbarometer",
  "Politik",
  "Politiker",
  "Politikerin",
  "Politikum",
  "Politische Ideologie",
  "Politische Partei",
  "Politisches System der Bundesrepublik Deutschland",
  "24hPolizei",
  "Polizei",
  "Poller",
  "Polterabend",
  "Poltern",
  "Polyglott",
  "Pomadenhengst",
  "Pompon",
  "Popcornkino",
  "Popper",
  "Populismus",
  "Populist",
  "Popular Vote",
  "Porno",
  "Pornobalken",
  "Portier",
  "Porträt",
  "Poseidon",
  "Positur",
  "Potemkinsches Dorf",
  "Potential",
  "Potluck",
  "Potsdammer Abkommen",
  "Präambel",
  "Prädestiniert",
  "Prädikatswein",
  "Präferenz",
  "Pragmatik",
  "Präimplantationsdiagnostik",
  "Praktikum",
  "Pränatal",
  "Pränataldiagnostik",
  "Pranke",
  "Präsenz",
  "Präsident",
  "Präsidentschaftswahl in den USA 2020",
  "Präsidialsystem",
  "Prävention",
  "Praxisgebühr",
  "Präzedenzfall",
  "Pre Crime Observation System",
  "Prekär",
  "Prekariat",
  "Prepper",
  "Presse",
  "Pressefreiheit",
  "Pressemeldung",
  "Pressemeldungen",
  "Prestige",
  "Pretty Good Privacy",
  "PRISM",
  "Privatisierung",
  "Privatsphäre",
  "Proband",
  "Probezeit",
  "Profan",
  "Profilieren",
  "Profund",
  "Programmkino",
  "Projekt",
  "Prokrastination",
  "Promiflash",
  "Prominent",
  "Pro­mis­ku­i­tät",
  "Prompter",
  "Propaganda",
  "Propriozeptive Neuromuskuläre Fazilitation",
  "Prophet",
  "Prosopagnosie",
  "Prostitution",
  "Protektionismus",
  "Protest",
  "Proteste im Iran 2017 und 2018",
  "Proteste in Clausnitz gegen Flüchtlinge",
  "Proteste in Heidenau gegen Ausländer",
  "Protestpartei",
  "Proviant",
  "Proxyserver",
  "Pseudonym",
  "Psychische Einschränkung",
  "Psychisch-Kranken-Gesetz",
  "Psychohygiene",
  "Public Viewing",
  "Publikation",
  "Publikum",
  "Pulitzer-Preis",
  "Pullover",
  "Pulswärmer",
  "Pult",
  "Pünktlichkeit",
  "Pussy Riot",
  "Pussyslap",
  "Putsch",
  "Putte",
  "Qigongkugeln",
  "Quad",
  "Quartal",
  "Quartär (Erde)",
  "Quasi",
  "Quasselstrippe",
  "Querlesen",
  "Querulant",
  "Quipu",
  "Quit­tung",
  "Rabatt",
  "Rabenmutter",
  "Raclette",
  "Radar",
  "Radball",
  "Radio",
  "Radiowelle",
  "Radsport Wettbewerbe",
  "Radwandern",
  "Rainbow Loom",
  "Ramadan",
  "Rampensau",
  "Ramsch",
  "Ranklotzen",
  "Rapunzel",
  "Rassismus",
  "Rastalocken",
  "Rasur",
  "Rathaus",
  "Ratifizierung",
  "Rattenfänger von Hameln",
  "Räuberleiter",
  "Räuberpistole",
  "Rauchgranate",
  "Rauchverbot",
  "Razzia",
  "Reaktionär",
  "Reaktion (Politik)",
  "Recep Tayyip Erdoğan",
  "Rechtsextremismus",
  "Rechtsfähig",
  "Recht zur Selbstverteidigung",
  "Rechenmeister",
  "Recycling",
  "Redenschreiber",
  "Redestab",
  "Redeverbot",
  "Redewendung",
  "Referendum",
  "Referent",
  "Reformationstag",
  "Refugium",
  "Regalien",
  "Regenbogenpresse",
  "Regenschirm",
  "Regierung",
  "Regierung von Deutschland",
  "Region",
  "Regisseur",
  "Reglementieren",
  "Rehabilitation",
  "Rehabilitationssport",
  "Reichskristallnacht",
  "Reichspogromnacht",
  "Reinheit",
  "Reise",
  "Reisepass",
  "Rektor",
  "Relativitätstheorie",
  "Reling",
  "Rente",
  "Rentner",
  "Repair Café",
  "Reparationen",
  "Reparatur",
  "Reptilien",
  "Republik Österreich",
  "Resolution",
  "Resolution 2401",
  "Resozialisierung",
  "Respektive",
  "Ressource",
  "Restaurationsbrot",
  "Restmüll",
  "Retard",
  "Retour",
  "Rettung im Mittelmeer am 6. Juni 2015",
  "Rettungssanitäter",
  "Rettungs-Kette",
  "Revolte",
  "Revolution",
  "Rezession",
  "Rezeptieren",
  "Rezeptpflicht",
  "Rezeptpflichtig",
  "Rhinophonie",
  "Rhönrad",
  "Richard Phillips (Kapitän)",
  "Richtfest",
  "Rikscha",
  "RMS Titanic",
  "Robert Crumb",
  "Robert Habeck",
  "Robert Koch",
  "Robinson Crusoe",
  "Robin Williams",
  "RoboCup",
  "RoboGames",
  "Roboterfußball",
  "Rohrpost",
  "Rollator",
  "Roller Derby",
  "Rollregal",
  "Rollstuhl",
  "Rollstuhlbasketball",
  "Rollstuhlcurling",
  "Rollstuhlfechten",
  "Rollstuhlrugby",
  "Rollstuhltennis",
  "Ronald Pofalla",
  "Roofer",
  "Rorschachtest",
  "Rosa Luxemburg",
  "Rosenmontag",
  "Rosig",
  "Ross und Reiter nennen",
  "Rot",
  "Roter Hering",
  "Rotkäppchen",
  "Rotkäppchen (Märchen)",
  "Roulette",
  "Rubble Bucket Challenge",
  "Rubensfigur",
  "Rückrufaktion",
  "Rucksacktourismus",
  "Rückstellregal",
  "Rücktritt",
  "Rudel",
  "Rudi Dutschke",
  "Rugby",
  "Rummel",
  "Rumpelstilzchen",
  "Runder Geburtstag",
  "Runder Tisch",
  "24/7",
  "Running Dinner",
  "Russenpeitsche",
  "Russischer Bürgerkrieg",
  "Russisch Roulette",
  "Russland",
  "Sabbatical",
  "Sabine Lisicki",
  "Sachkundenachweis für Hunde",
  "Sage",
  "Sakristei",
  "Schafott",
  "Schmähkritik",
  "Schmerzgedächtnis",
  "Schmetterling",
  "Schnorrer",
  "Schutzbehauptung",
  "Schutzstaffel",
  "Science-Fiction",
  "Safer Internet Day",
  "Sahra Wagenknecht",
  "Sailor Moon",
  "Saint Patricks Day",
  "Salamitaktik",
  "Salmonellen",
  "Salpicon",
  "Samowar",
  "San Diego Comic-Con International",
  "Sandwichtoaster",
  "Sanitätshaus",
  "Sanktion",
  "Sari",
  "Sarkasmus",
  "Satire",
  "Saturnalien",
  "Satzungsgemäß",
  "Säugetier",
  "Säugling",
  "Sautieren",
  "Schach",
  "Schachuhr",
  "Schachweltmeisterschaft 2014",
  "Schabbat",
  "Schattenboxen",
  "Schattenkabinett",
  "Schatzmeister",
  "Schadenfreude",
  "Schadstoff",
  "Schaltjahr",
  "Schamhaarperücke",
  "Schar­la­tan",
  "Scharmützel",
  "Schaulustiger",
  "Schaumparty",
  "Schauspieler",
  "Scheibenschlagen",
  "Scheide",
  "Schelten",
  "Schema F",
  "Schenkladen",
  "Schiedsrichter",
  "Schiedsrichterball",
  "Schien­bein­scho­ner",
  "Schießerei in San Bernardino",
  "Schikane",
  "Schimpfwort",
  "Schlaganfall",
  "Schlafittchen",
  "Schlafmaske",
  "Schlauchtuch",
  "Schleudersitz",
  "Schleuser",
  "Schloss Bellevue",
  "Schluckstörung",
  "Schmalzgreben",
  "Schmotziger Donnerstag",
  "Schneekanone",
  "Schneemann",
  "Schneiderpuppe",
  "Schnelllesen",
  "Schnickschnack",
  "Schniposa",
  "Schokokuss",
  "Schöne neue Welt",
  "Schönheitsoperation",
  "Schubkarre",
  "Gerd Schönfelder",
  "Schredder",
  "Schrift",
  "Schriftdolmetscher",
  "Schriftsprachstörungen",
  "Schriftsteller",
  "Schuh",
  "Schuh­platt­ler",
  "Schulbegleitung",
  "Schule",
  "Schulpflicht",
  "Schultüte",
  "Schulzwang",
  "Schwalbe (Fußball)",
  "Schwanger",
  "Schwangerschaftstest",
  "Schwarzarbeit",
  "Schwarze Pädagogik",
  "Schwarzes Loch",
  "Schweden",
  "Schwedenfeuer",
  "Schwein",
  "Schweiz",
  "Schwellenland",
  "Schweinegrippe",
  "Schwul",
  "Schwulität",
  "Scone",
  "Scrapbook",
  "Scrapbooking",
  "Sebastian Edathy",
  "Sebastianismus",
  "Sechserträger",
  "Secondhandladen",
  "Sehbehindertentag",
  "Sehenswürdigkeit",
  "Seifenkiste",
  "Seiltanz",
  "Selbstbewusstsein",
  "Selbstbefriedigung",
  "Selbstbestimmung",
  "Selbsterfüllende Prophezeiung",
  "Selbsthilfeorganisation",
  "Selbstverteidigung",
  "Selchen",
  "Selfie",
  "Selfie stick",
  "Selma Lagerlöf",
  "Semantisch-lexikalische Störung",
  "Semester",
  "Senfautomat",
  "Senseo",
  "Separatismus",
  "Sergey Brin",
  "Serpentine",
  "Service",
  "Setlist",
  "Sex",
  "Sexismus",
  "Sexsymbol",
  "Sexting",
  "SGB IX",
  "Shakuntala Devi",
  "Shire Horse",
  "Shisha",
  "Shitstorm",
  "Showrooming",
  "Sich an die eigene Nase fassen",
  "Sicherer Herkunftsstaat",
  "Sichtflug",
  "Siehe",
  "Sigmar Gabriel",
  "Sigmatismus",
  "Silbe",
  "Silvester",
  "Silvesterbräuche",
  "Silvia Schmidt",
  "SIM-Karte",
  "Simpel",
  "Simsen",
  "Simultanschach",
  "Singles’ Day",
  "Singular",
  "Sinnfrei",
  "Sinnieren",
  "Sixpack",
  "Slam",
  "Slamball",
  "Slogan",
  "Sneak Preview",
  "Swinserl",
  "Siri",
  "Sitar",
  "Sitcom",
  "Sit-in",
  "Sitzfußball",
  "Sixtinische Kapelle",
  "Skandal",
  "Skateboard",
  "Skilanglauf",
  "Skirtini",
  "Sklave",
  "Skriptkiddie",
  "Skullet",
  "Skype",
  "Slackline",
  "Sledge-Eishockey",
  "Smalltalk",
  "Smartphone",
  "Smartlet",
  "Smartwatch",
  "SMS",
  "Social Bot",
  "Social media",
  "Sockenhalter",
  "Sockenklammer",
  "Sodbrennen",
  "Solander-Box",
  "Soldat",
  "Soldat auf Zeit",
  "Solidarität",
  "Solidaritätszuschlag",
  "Sommergewinn",
  "Sommerloch",
  "Sommerzeit",
  "Sondierung (in der Politik)",
  "Sondierungsgespräch",
  "Sonnabend",
  "Sonnenfinsternis",
  "Sonnensystem",
  "Sonntag",
  "Sonntagsfrage",
  "Sozialamt",
  "Soziale Marktwirtschaft",
  "Soziale Sicherheit",
  "Soziales Milieu",
  "Soziales Netzwerk",
  "Soziales Netzwerk (Internet)",
  "Sozialismus",
  "Soziallotterie",
  "Sozialtourismus",
  "Spam",
  "Spanien",
  "Spanier in Deutschland",
  "Spanische Sprache",
  "Sparbuch",
  "Spardose",
  "Sparen",
  "SPD",
  "Special Olympics",
  "Special Olympics 2015",
  "Speedklettern",
  "Speisekammer",
  "Spektakulär",
  "Sperrfrist (für Nachrichten)",
  "Spider-Man",
  "Spiegelkugel",
  "Spielautomat",
  "Spina bifida",
  "Spitzenkandidat",
  "Spleen",
  "Spoiler",
  "Spoiler (Comicfigur)",
  "Spoiler (Fahrzeug)",
  "Spoiler (Information)",
  "Spontansprache",
  "Sporadisch",
  "Sport",
  "Sportgerät",
  "Spotlight Effekt",
  "Spöttisch",
  "Spielkonsole",
  "Sprachbad",
  "Sprachdomäne",
  "Sprache",
  "Sprachentwicklungsverzögerung",
  "Spracherkennung",
  "Spracherwerb",
  "Sprachregelung",
  "Sprachreise",
  "Sprachsteuer",
  "Sprachstörung",
  "Sprachsynthese",
  "Sprachwechsel",
  "Sprechapraxie",
  "Sprecherwechsel",
  "Sprichwort",
  "Spritzenpumpe",
  "Sprössling",
  "Sputnik Moment",
  "Squash",
  "Staat",
  "Staatenlos",
  "Staatliche Kunsthalle Karlsruhe",
  "Staatsanwalt",
  "Staatsbesuch",
  "Staatsbürgerschaft",
  "Staatsverschuldung",
  "Stabilisieren",
  "Stadion",
  "Stadtrat",
  "Staffelei",
  "Stalking",
  "Stammwähler",
  "Standard",
  "Stanisław Lem",
  "Stan Lee",
  "Starrsinn",
  "Stasi",
  "Statiker",
  "Statist",
  "Stativ",
  "Stegreif",
  "Steinzeit",
  "Stephen Hawking",
  "Sternschnuppe",
  "Stethoskop",
  "Steuer",
  "Steuerflucht",
  "Steve Jobs",
  "Steve Wozniak",
  "Stierkampf",
  "Stierlauf",
  "Stilblüte",
  "Stille SMS",
  "Stimmlippenlähmung",
  "Stimmstörung",
  "Stimmzettel",
  "Stinkefinger",
  "Stirnlampe",
  "Stockbrot",
  "Stockholm-Syndrom",
  "Stöpsel-Verein",
  "Stottern",
  "Strapse",
  "Strand",
  "Straßenmusik",
  "Straßenschlacht",
  "Straßenverkehrsordnung",
  "Street Food",
  "Stressball",
  "Streik",
  "Streisand-Effekt",
  "Streitschrift",
  "Streit wegen Erdoğan Satire",
  "Striptease",
  "Stroboskop",
  "Strohmann",
  "Stromsparen",
  "Student",
  "Studium",
  "StudiVZ",
  "Stunde",
  "Stunde Null",
  "Stundung",
  "Stuntman",
  "Sturm Friederike",
  "Sturmmaske",
  "Stutenkerl",
  "Substantiv",
  "Subtil",
  "Sucht",
  "Sue Gardner",
  "Suezkanal",
  "Suizid",
  "Sukkot",
  "Sukzessiv",
  "Sünde",
  "Sündenbock",
  "Super Bowl",
  "Superfood",
  "Supervision",
  "Suppenküche",
  "Supplik",
  "Surfen",
  "Surrealismus",
  "SUV",
  "Swatting",
  "SWIFT-Abkommen",
  "Symposion",
  "Symptom",
  "Synagoge",
  "Synchronsprecher",
  "Synonym",
  "Tabak",
  "Tabledance",
  "Tablette",
  "Tacheles reden",
  "Tackling",
  "Tadeln",
  "Tag",
  "Tag der Bibliotheken",
  "Tag der Deutschen Einheit",
  "Tag der deutschen Sprache",
  "Tag der Erde",
  "Tag der Organspende",
  "Tag der Sysadmina und des Sysadmins",
  "Tag der Vereinten Nationen",
  "Tag gegen Rassismus",
  "Tag des Blindenhundes",
  "Tag des Buches",
  "Tag des Gedenkens an die Opfer des Nationalsozialismus",
  "Tag des Hundes",
  "Tag des Meeres",
  "Tag des weißen Stockes",
  "Taifun",
  "Taifun Haiyan",
  "Talar",
  "Talent",
  "Tamagotchi",
  "Tandem",
  "Tanga",
  "Tankini",
  "Tante Emma Laden",
  "Tanzverbot",
  "TAO",
  "Tarif",
  "Tarifvertrag",
  "Taschenlampe",
  "Tastatur",
  "Tätigkeitswort",
  "Tätowierung",
  "Tatsache",
  "Taubblind",
  "Tausendsassa",
  "Tausendundeine Nacht",
  "Tautologie (in der Sprache)",
  "Teardown",
  "Technisches Hilfswerk",
  "Teenager",
  "Teilhabe",
  "Teilzeitarbeit",
  "Telearbeit",
  "Telefonvorwahl",
  "Telefonwarteschleife",
  "Teleshopping",
  "Teletext",
  "Tempora",
  "Tennis",
  "Termin",
  "Terrine",
  "Terror",
  "Terry Fox",
  "Tesla, Inc.",
  "Testament",
  "Test word",
  "TEuro",
  "Teuro",
  "Texterkennung",
  "Thanksgiving",
  "The Color Run",
  "Themenliste Weltwassertag",
  "Themenpark",
  "Theodor Fontane",
  "Theodor W. Adorno",
  "The Plaza",
  "Therapieziel",
  "These",
  "Thilo Sarrazin",
  "Thomas Alva Edison",
  "Thomas de Maizière",
  "Thomas Hitzlsperger",
  "Thomas Morus",
  "Thora",
  "Thüringen",
  "Ticker",
  "Tier",
  "Tiger",
  "Tikitaka",
  "TikTok",
  "Tim Berners-Lee",
  "Tinder",
  "Tippfehler",
  "TiSA",
  "Tischtennis",
  "Tischvorlage",
  "Tl;dr",
  "Toki Luka",
  "Toki Pona",
  "Toleranz",
  "Tollwut",
  "Tomate",
  "Tomatina",
  "Tom Mutters",
  "Tora",
  "Torarolle",
  "Tornado in Bützow",
  "Torwart",
  "To­ta­li­ta­ris­mus",
  "Totensonntag",
  "Toter Briefkasten",
  "Toter Link",
  "Totes Rennen",
  "Totschlagargument",
  "Toga",
  "Torlinientechnologie",
  "Tor-Netzwerk",
  "Touchscreen",
  "Tour de France",
  "Tour de France 2015",
  "Tour de France 2017",
  "Towel Day",
  "Trainer",
  "Tram",
  "Trampen",
  "Transatlantisches Freihandelsabkommen",
  "Trans­gen­der",
  "Tran­su­se",
  "Transferleistung",
  "Transportpalette",
  "Treibgut",
  "Treibgutrechen",
  "Trend",
  "Trendwende",
  "Tretauto",
  "Triage",
  "Trident Juncture 2018",
  "Trimester",
  "Trinkgeld",
  "Trinkwasser",
  "Trisomie",
  "Trisomie 21",
  "Trivial",
  "Trockenhaube",
  "Troika",
  "Troll",
  "Trollface",
  "Trottoir",
  "Tschador",
  "Tschernobyl",
  "Tuğçe Albayrak",
  "Tugend",
  "Tunwort",
  "Turing-Test",
  "Türkei",
  "Türkei-PKK-Konflikt",
  "Türkeistämmige in Deutschland",
  "Turnen",
  "Türsteher",
  "TÜV",
  "TV-Duell",
  "Tween",
  "Twerking",
  "Twitter",
  "Typografie",
  "Überforderung",
  "Übergangsgeld",
  "Übergriffe in Köln 2015/2016",
  "Übername",
  "Übersetzer",
  "Übersetzung (Sprache)",
  "Übertretung",
  "Überwachungsstaat",
  "Ubuntu",
  "UEFA",
  "Uhr",
  "Ukraine",
  "UKUSA",
  "Ulrich Hoeneß",
  "Ultimatum",
  "Ultraverarbeitete Nahrung",
  "Um den heißen Brei reden",
  "Umgangssprache",
  "Umlaufgitter",
  "Umschaltsperre",
  "Umweltprämie",
  "Umweltschutz",
  "Unabhängigkeit",
  "Unabhängigkeitstag",
  "UN-Antifolterkonvention",
  "UN-Behindertenkonvention",
  "Unboxing",
  "Unentgeltlich",
  "UNESCO",
  "Ungarische Sprache",
  "Ungarn",
  "Ungewiss",
  "Ungooglebar",
  "Universal Media Disc",
  "Universum",
  "Unken",
  "UN-Klimakonferenz in Paris 2015",
  "UN-Konvention über die Rechte von Menschen mit Behinderungen",
  "Unlängst",
  "UNO",
  "Unspektakulär",
  "Unst Bus Shelter",
  "Unter einer Decke stecken",
  "Unterfangen",
  "Unterrichtspflicht",
  "Unterschichtenfernsehen",
  "Unterschwellig",
  "Unterstützte Kommunikation",
  "Unterstützungsunterschrift",
  "Untertitel",
  "Unterwäsche",
  "Upgrade",
  "Upload",
  "Upload-Filter",
  "Üppig",
  "Upcycling",
  "Urbi et orbi",
  "Urologe",
  "USA",
  "Usain Bolt",
  "USB-Stick",
  "US-Dollar",
  "Vagabund",
  "Vakatseite",
  "Valentinstag",
  "Valet Parking",
  "Vanessa Low",
  "Vatertag",
  "Vaterunser",
  "Vatikanstadt",
  "Vault 7",
  "Vegan",
  "Veggieday",
  "Venezuela",
  "Venus",
  "Venus (Planet)",
  "Verantwortung",
  "Veräußern",
  "Verb",
  "Verbale Entwicklungsdyspraxie",
  "Verballhornung",
  "Verbot",
  "Verbotszeichen",
  "Verbraucherpreisindex",
  "Verbraucherschutz",
  "Verbrechenskarte",
  "Verbündeter",
  "Verdauungsschnaps",
  "Vereidigung",
  "Verena Bentele",
  "Verendung",
  "Verfassung",
  "Verfassungswidrigkeit",
  "Vergewaltigung",
  "Verhandlungsbasis",
  "Verhütungsmittel",
  "Verkehrswende",
  "Verkehrszeichen",
  "Verkehrszeichen 136",
  "Verkehrszeichen 138",
  "Vermögen",
  "Vermummungsverbot",
  "Vernetzen",
  "Verordnen",
  "Verschlüsselung",
  "Verschreiben",
  "Verschreiben (Rezept)",
  "Verschreibungspflicht",
  "Verschwörung",
  "Verschwörungstheorie",
  "Verteidigungsminister",
  "Vertrauensfrage",
  "Vertrauensperson",
  "Verwaltung",
  "Verzögerungszeit",
  "Victoria Justice",
  "Victory Zeichen",
  "Victorious",
  "Video-Überwachung",
  "Videothek",
  "Vielfalt",
  "Vignette",
  "Virginia Woolf",
  "Virus",
  "Visarun",
  "Visegrád-Gruppe",
  "Visum",
  "Vitali Klitschko",
  "Vogel",
  "VoiceOver",
  "Vokal",
  "Vokuhila",
  "Volker Elis Pilgrim",
  "Volksdroge",
  "Volkshochschule",
  "Volksmärchen",
  "Volkspartei (in der Politik)",
  "Völlerei",
  "Volleyball",
  "Volljährig",
  "Vollwerternährung",
  "Voluminös",
  "Volxküche",
  "Vorankündigung",
  "Vorarbeiter",
  "Vorfall im Thalys-Zug 9364",
  "Vorkoster",
  "Vorratsdatenspeicherung",
  "Vorreiter",
  "Vorschriftzeichen",
  "Vorsorgevollmacht",
  "Vorteil (Fußball)",
  "Vortrag",
  "Vorwahl (in der Politik)",
  "Von der Wiege bis zur Bahre",
  "Vonnöten",
  "VW-Abgas-Skandal",
  "Waffenlobby",
  "Waffle House",
  "Wahl",
  "Wahlbenachrichtigung",
  "Wahlbeobachter",
  "Wahlberechtigte",
  "Wahlen 2017",
  "Wählerverzeichnis",
  "Wahlfälschung",
  "Wahlgeheimnis",
  "Wahlhelfer",
  "Wahlkampf",
  "Wahlkreis",
  "Wahlleiter",
  "Wahlliste",
  "Wahl-O-Mat",
  "Wahlprogramm",
  "Wahlrecht",
  "Wahlstimme",
  "Wahlversprechen",
  "Währung",
  "Wahrzeichen",
  "Walentina Wladimirowna Tereschkowa",
  "Walfänger",
  "Walfänger (Schiff)",
  "Walpurgisnacht",
  "Walt Disney",
  "Wams",
  "Wanderlust",
  "WannaCry",
  "Wappen",
  "Wardriving",
  "Warentauschtag",
  "Wärschtlamo",
  "Warteschlange",
  "Waschbrettbauch",
  "Wasserdinosaurier",
  "Wasserkraft",
  "Wasserkreislauf",
  "Wasser läuft im Munde zusammen",
  "Wasserwerfer",
  "Waterboarding",
  "Wau Holland",
  "Web 2.0",
  "Webbrowser",
  "Webinar",
  "Webseite",
  "Wecker",
  "Wegwerfgesellschaft",
  "Weiberfastnacht",
  "Weichtier",
  "Weihnachten",
  "Weihnachtsbaum",
  "Weihnachtsbrauch (Deutsch)",
  "Weihnachtsmann",
  "Weißbuch",
  "Weiterbildung",
  "Werkbank",
  "Werktag",
  "Weltalphabetisierungstag",
  "Weltblutspendetag",
  "Welt-Down-Syndrom-Tag",
  "Weltempfänger",
  "Weltgesundheitsorganisation",
  "Weltgipfel für nachhaltige Entwicklung 2015",
  "Welthundetag",
  "Welternährungstag",
  "Weltflüchtlingstag",
  "Weltfremd",
  "Weltfrieden",
  "Weltfriedensglocke",
  "Weltkindertag",
  "Weltkrieg",
  "Weltkulturerbe",
  "Weltladen",
  "Weltmalariatag",
  "Weltnichtrauchertag",
  "Weltreligion",
  "Weltspartag",
  "Welttag der Aufklärung über Autismus",
  "Welttag der Meteorologie",
  "Welttag des Buches",
  "Welttag des Schneemanns",
  "Weltumwelttag",
  "Weltwassertag",
  "Weltzeit",
  "Werbung",
  "Werkstatt für Menschen mit Behinderung",
  "Werkstattleitung",
  "Werkstattrat",
  "Wertkette",
  "Wertstoffhof",
  "Wertstofftonne",
  "Westdeutscher Rundfunk",
  "Wetter",
  "Wetterregel",
  "Wetterumschwung",
  "Wettessen",
  "WfB",
  "WfbM",
  "WfMmB",
  "Whail Tail",
  "WhatsApp",
  "Wheelmap.org",
  "Where's George",
  "WHO",
  "Wichteln",
  "Wii",
  "Wiki",
  "Wikipedia",
  "Wimpel",
  "Wind",
  "Windenergie",
  "Windows",
  "Wing Chun",
  "Wintereinbruch",
  "Winterspeck",
  "Winterzeit",
  "Wirtschaft",
  "Wladimir Klitschko",
  "WLAN",
  "Woge",
  "Wohngemeinschaft",
  "Wohngeld",
  "Wohnheim",
  "Wohnung",
  "Wohnzimmer",
  "Wok",
  "Wolpertinger",
  "Wohn- und Teilhabegesetz",
  "Wolf",
  "Wolfgang Amadeus Mozart",
  "Wolf (Tier)",
  "Wonder Woman",
  "Word",
  "World-Klapp",
  "World Naked Bike Ride",
  "World Wide Web",
  "Workaholic",
  "Workaround",
  "Workshop",
  "Wort des Jahres",
  "Wortschatz",
  "Wörterbuch",
  "Wulffen",
  "Würde des Menschen ist unantastbar",
  "Wüstenschiff",
  "Wutbürger",
  "Xi Jinping",
  "XKeyscore",
  "Yellowstone-Nationalpark",
  "Yếm",
  "Yoga",
  "YOLO",
  "Youtube",
  "Yuppie",
  "Zahn",
  "Zahnarzt",
  "Zebrastreifen",
  "Zebrastreifen (Verkehr)",
  "Zeit",
  "Zeitansage",
  "Zeitnehmer",
  "Zeitschaltuhr",
  "Zeitumstellung",
  "Zeitverschiebung",
  "Zeitvertreib",
  "Zeitwaage",
  "Zeitzeugen",
  "Zelle (Biologie)",
  "Zero Rating",
  "Zettelkasten",
  "Zielgruppe",
  "Zigarette",
  "Zigeuner",
  "Zimt",
  "Zimtzicke",
  "Zins",
  "Zivilisation",
  "Zivilcourage",
  "Zombie",
  "Zombie Walk",
  "Zombie (Wesen)",
  "Zugangsgesellschaft",
  "Zugänglichmachungsverordnung",
  "Zuhälter",
  "Zukunft",
  "Zulassung",
  "Zungenbrecher",
  "Zupfinstrument",
  "Zwangsheirat",
  "Zwei-Faktor-Authentifizierung",
  "Zweifrontenkrieg",
  "Zweiter Arbeitsmarkt",
  "Zweitsprache",
  "Zweitstimme",
  "Zwicker",
  "Zwischen den Jahren",
  "Zwist",
  "Zynismus",
  "Zyprer",
]

const termsEigene = [
  "Arbeitszeugnis",
  "Berufsausbildung",
  "Covid-19",
  "Durchschnittsgehalt",
  "Einkommensteuer",
  "Fachhochschule",
  "Gesetz",
  "Hochschulabschluss",
  "Impfung",
  "Jahresabschluss",
  "Krankenversicherung",
  "Lebenslauf",
  "Mehrwertsteuer",
  "Nachhaltigkeit",
  "Organisation",
  "Pflegeversicherung",
  "Quarantäne",
  "Rechnung",
  "Schulabschluss",
  "Tarifvertrag",
  "Umsatzsteuer",
  "Versicherung",
  "Wirtschaft",
  "Zwischenzeugnis",
  "Änderung",
  "Überstunden",
  "Öffentlichkeit",
  "Arbeitszeugnis",
  "Berufsausbildung",
  "Covid-19",
  "Durchschnittsgehalt",
  "Einkommensteuer",
  "Fachhochschule",
  "Gesetz",
  "Hochschulabschluss",
  "Impfung",
  "Jahresabschluss",
  "Krankenversicherung",
  "Lebenslauf",
  "Mehrwertsteuer",
  "Nachhaltigkeit",
  "Organisation",
  "Pflegeversicherung",
  "Quarantäne",
  "Rechnung",
  "Schulabschluss",
  "Tarifvertrag",
  "Umsatzsteuer",
  "Versicherung",
  "Wirtschaft",
  "Zwischenzeugnis",
  "Änderung",
  "Überstunden",
  "Öffentlichkeit",
];

// Combine the two
const terms = [...new Set([...termsHurraki, ...termsEigene])];
// const terms = [...new Set([...termsEigene])];

// Sort them albabetically
terms.sort((a, b) => a.localeCompare(b));

// export
module.exports = {
  terms: terms
};


