import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Alert = ({ title, description, expireTime, onClose, variant = 'info' }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (expireTime) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, expireTime);

      return () => clearTimeout(timer);
    }
  }, [expireTime, onClose]);

  if (!isVisible) return null;

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const variantClasses = {
    info: 'bg-blue-100 text-blue-700',
    default: 'bg-white text-gray-700',
    error: 'bg-red-100 text-red-700',
  };

  return (
    <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full max-w-lg border border-gray-500 ${variantClasses[variant]} p-4 rounded shadow-lg`}>
      <div className="flex justify-between items-start">
        <h3 className="text-base font-semibold">{title}</h3>
        <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 bg-white border-none">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <p className="mt-2 text-sm" dangerouslySetInnerHTML={{ __html: description }} />
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleClose}
          className="px-4 py-2 bg-white text-black text-sm rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          Akzeptieren
        </button>
      </div>
    </div>
  );
};

export default Alert;
