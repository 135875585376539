import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { posts } from '../../data/postsData';
import LukasCard from '../authorCards/lukas';
import PascalCard from '../authorCards/pascal';
import ReadingProgressBar from './ReadingProgressBar';
import SocialShareButtons from '../share/SocialShareButtons'; // Add this import
import SkeletonLoader from './SkeletonLoader'; // Add this import
import '../../pages/Page.css';
import '../../App.css';

const BlogPost = () => {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Find the post by slug
  const post = posts.find((p) => p.slug === slug);

  useEffect(() => {
    if (post) {
      setIsLoading(true);
      fetch(`/posts/${slug}.html`)
        .then((response) => response.text())
        .then((html) => {
          setContent(html);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error loading post:', error);
          setIsLoading(false);
        });
    }
  }, [slug, post]);

  if (!post) {
    return <div>Post not found</div>;
  }

  const shareUrl = window.location.href;
  const shareText = post.title;

  return (
    <div className="info-page bg-background-bright dark:bg-background-dark">
      <ReadingProgressBar />
      <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.description} />
          <meta name="keywords" content={post.keywords} />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={post.img} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image:alt" content="Description of the image" />
          <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.title} />
          <meta name="twitter:description" content={post.description} />
          <meta name="twitter:image" content={post.img} />
          <meta name="twitter:image:alt" content="Description of the image" />
          <meta name="twitter:site" content="@YourTwitterHandle" />
          <link rel="canonical" href={window.location.href} />
          <meta httpEquiv="Content-Language" content="de" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": "${post.title}",
              "description": "${post.description}",
              "image": "${post.img}",
              "author": {
                "@type": "Person",
                "name": "Lukas Hecker"
              },
              "publisher": {
                "@type": "Leichte Sprache Uebersetzer",
                "name": "https://www.leichte-sprache-uebersetzer.de/",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.leichte-sprache-uebersetzer.de/favicon.ico"
                }
              },
              "datePublished": "${post.date}",
              "dateModified": "${post.dateModified || post.date}"
            }
          `}
          </script>
          <style>
          {`
            .blog-content {
              min-height: 2000px; /* Adjust this value based on your average content height */
            }
            
            html {
              font-display: optional;
            }
            
            .blog-img {
              
              width: 100%;
              max-width: 860px;
              height: auto;
            }

            /* Add dark mode styles for blog content */
            .dark .blog-content h1,
            .dark .blog-content h2,
            .dark .blog-content h3,
            .dark .blog-content h4,
            .dark .blog-content h5,
            .dark .blog-content h6 {
              color: #f3f4f6;
            }

            .dark .blog-content p,
            .dark .blog-content li,
            .dark .blog-content blockquote {
              color: #d1d5db;
            }

            .dark .blog-content a {
              color: #60a5fa;
            }

            .dark .blog-content a:hover {
              color: #93c5fd;
            }
          `}
        </style>
      </Helmet>
      <div className='text-lg text-gray-800 dark:text-gray-200' style={{ minHeight: '1000px' }}>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <div 
            className="blog-content prose prose-lg max-w-none dark:prose-invert
              prose-headings:text-gray-900 dark:prose-headings:text-gray-100
              prose-p:text-gray-700 dark:prose-p:text-gray-300
              prose-a:text-blue-600 dark:prose-a:text-blue-400
              prose-strong:text-gray-900 dark:prose-strong:text-gray-100
              prose-code:text-gray-800 dark:prose-code:text-gray-200
              prose-pre:bg-gray-100 dark:prose-pre:bg-gray-800
              prose-blockquote:text-gray-700 dark:prose-blockquote:text-gray-300"
            dangerouslySetInnerHTML={{ __html: content }} 
          />
        )}
      </div>
      <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-8">
        {post.author === "Lukas Hecker" && <LukasCard />}
        {post.author === "Pascal Haller" && <PascalCard />}
        <div className="mt-6">
          <SocialShareButtons shareUrl={shareUrl} shareText={shareText} />
        </div>
      </div>
    </div>    
  );
};

export default BlogPost;
