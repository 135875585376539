import React, { useState } from 'react';

const GlossaryList = ({ terms }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  const groupedTerms = terms.reduce((acc, term) => {
    const firstLetter = term[0].toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(term);
    return acc;
  }, {});

  const flattenedTerms = Object.values(groupedTerms).flat();
  const totalPages = Math.ceil(flattenedTerms.length / itemsPerPage);
  const currentTerms = flattenedTerms.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const renderPaginationItems = () => {
    const items = [];
    const range = 2;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - range && i <= currentPage + range)) {
        items.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-3 py-1 rounded-md border border-gray-300 dark:border-gray-700 text-base focus:bg-gray-100 ${
              currentPage === i
                ? 'text-black font-bold underline underline-offset-4'
                : 'bg-background-bright-2 text-gray-700 hover:bg-gray-100'
            }`}
          >
            {i}
          </button>
        );
      } else if (items[items.length - 1] !== '...') {
        items.push('...');
      }
    }

    return items;
  };

  return (
    <div className="w-full max-w-4xl mx-auto bg-background-bright dark:bg-background-dark rounded-md border-1 border-gray-200 dark:border-gray-700">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full py-2 px-4 bg-background-bright-2 dark:bg-background-dark text-black dark:text-text-dark rounded-md border-1 border-gray-300 dark:border-gray-700 shadow-md transition duration-700 mb-4"
      >
        {isOpen ? 'Glossar Einträge ▲' : 'Glossar Einträge ▼'}
      </button>

      <div className={`overflow-hidden transition-all duration-700 ease-in-out ${isOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="bg-background-bright-2 border border-gray-300 dark:border-gray-700 dark:bg-background-dark p-6 rounded-md shadow-md">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {currentTerms.map((term) => (
              <a
                key={term}
                href={`/glossar/${encodeURIComponent(term)}`}
                className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 hover:underline text-base"
                title={term}
              >
                {term.length > 20 ? term.substring(0, 20) + '...' : term}
              </a>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="flex justify-center items-center space-x-2 mt-6">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 rounded-md bg-white dark:bg-background-dark border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-700 disabled:opacity-50 text-bold"
              >
                ←
              </button>
              {renderPaginationItems()}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-1 rounded-md bg-white dark:bg-background-dark border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-700 disabled:opacity-50"
              >
                →
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlossaryList;