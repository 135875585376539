import React from 'react';
import { useTranslation } from 'react-i18next';



  
const Services = () => {
  const { t } = useTranslation();

  const servicesData = [
    {
      icon: '🏛️', 
      title: t('services.servicesData.title-1'),
      description: t('services.servicesData.description-1')
    },
    {
      icon: '🏢', 
      title: t('services.servicesData.title-2'),
      description: t('services.servicesData.description-2')
    },
    {
      icon: '🤝', 
      title: t('services.servicesData.title-3'),
      description: t('services.servicesData.description-3')
    },
    {
      icon: '📝', 
      title: t('services.servicesData.title-4'),
      description: t('services.servicesData.description-4')
    },
  ];

  return (
    <div className="max-w-4xl mx-auto py-12 mt-6 border-transparent dark:border-text-dark">
      <h2 className="text-3xl font-bold text-center mb-8 text-text-bright dark:text-text-dark" dangerouslySetInnerHTML={{ __html: t('services.title') }}></h2>
      <p className='text-center text-gray-500 dark:text-gray-400 mb-8 text-base' dangerouslySetInnerHTML={{ __html: t('services.paragraph') }}></p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {servicesData.map((service, index) => (
          <div key={index} className="bg-background-bright-2 border border-gray-300 dark:bg-background-dark dark:border-gray-700 shadow-none rounded-lg p-6 text-center hover:shadow-md hover:border hover:border-gray-400 dark:hover:border-gray-600 transition-all duration-300">
            <div className="text-4xl mb-4">{service.icon}</div>
            <h3 className="text-xl font-semibold mb-2 text-text-bright dark:text-text-dark" dangerouslySetInnerHTML={{ __html: service.title }}></h3>
            <p className="text-left text-gray-600 dark:text-gray-400 text-base" dangerouslySetInnerHTML={{ __html: service.description }}></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;