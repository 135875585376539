export const charsPerPage = 1800;

export const charsPerRequestUnregistered = 1800;
export const charsPerRequestRegistered = 3600;

export const charLimitBonus = 900;
export const charLimitFree = 3 * charsPerPage + charLimitBonus;
export const charLimitRegistered = 6 * charsPerPage + charLimitBonus;

// export const BASE_URL = "https://leichte-sprache-app-3a36ecbb26fd.herokuapp.com/api/v1"
export const BASE_URL = "https://leichte-sprache-app-eu-8cd82c51f5c4.herokuapp.com/api/v1"
// export const BASE_URL = "http://localhost:8000/api/v1"
export const STRIPE_PUBLISHABLE_KEY = "pk_test_51PiPjXRoaE8ZvwQ5qdoceWym1N8oepPA1x9Xfltb3DaWY423F9oEeQHslrEhCpjsJC0qiLBgR0qTjr9npRhAGP0Y00hIMvm361";

// Send Mail Key (Mailgun.com)
export const SendmailApiKey = "af955b05f72ab4c87305c752fef85bcd-2b91eb47-bbf82c12";
export const SendmailKeyId = "2b91eb47-bbf82c12";
export const MailDomain = 'mail.leichte-sprache-uebersetzer.de';
export const MailFrom = 'Leichte Sprache Übersetzer <hallo@mail.leichte-sprache-uebersetzer.de>';