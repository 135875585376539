import React, { useEffect, lazy, Suspense, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

import { AuthProvider } from './context/AuthContext';
import AuthWrapper from './components/auth/AuthWrapper';
import ScrollToTop from './utils/scroll';

import NavbarComponent from './components/NavbarComponent';
import FooterComponent from './components/FooterComponent';
import Home from './pages/Home';
import BlogPost from './components/blog/BlogPost';
import UberUns from './pages/UberUns';
import Mission from './pages/Mission';
import Glossary from './pages/Glossary';
import BlogList from './components/blog/BlogList';
import Pricing from './pages/Pricing';
import './i18n'; // Add this import
import { Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';

// const Home = lazy(() => import('./pages/Home'));
// const Glossary = lazy(() => import('./pages/Glossary'));
// const GlossaryTerm = lazy(() => import('./pages/GlossaryTerm'));
const GlossaryTerm = lazy(() => import('./pages/GlossaryTermNew'));
// const UberUns = lazy(() => import('./pages/UberUns'));
// const Mission = lazy(() => import('./pages/Mission'));
const Datenschutz = lazy(() => import('./pages/Datenschutz'));
const Impressum = lazy(() => import('./pages/Impressum'));
const Login = lazy(() => import('./pages/Login'));

const ProfileLayout = lazy(() => import('./components/profile/ProfileLayout'));
const UserData = lazy(() => import('./pages/profile/UserData'));
const Translations = lazy(() => import('./pages/profile/Translations'));
const Subscription = lazy(() => import('./pages/profile/Subscription'));
// const APIKeys = lazy(() => import('./pages/profile/APIKeys'));
const Contact = lazy(() => import('./pages/Contact'));
const ActivateAccount = lazy(() => import('./pages/ActivateAccount'));
// const BlogList = lazy(() => import('./components/blog/BlogList'));
// const BlogPost = lazy(() => import('./components/blog/BlogPost'));

// Pricing related pages
// const Pricing = lazy(() => import('./pages/Pricing-placeholder'));
// const Pricing = lazy(() => import('./pages/Pricing'));
const Checkout = lazy(() => import('./pages/Checkout'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));

function AppContent() {
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const timerRef = useRef(null); // Add a ref to store the timer ID


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('loginSuccess') === 'true') {
      setShowLoginSuccess(true);
      // Remove the query parameter
      searchParams.delete('loginSuccess');
      navigate(location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : ''), { replace: true });

      // Hide the alert after 5 seconds
      timerRef.current = setTimeout(() => {
        setShowLoginSuccess(false);
      }, 5000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
  }, [location, navigate]);

  return (
    <>
      <NavbarComponent />
      {showLoginSuccess && (
        <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-white text-black px-6 py-3 rounded-md border border-gray-300 shadow-lg z-50 text-lg font-semibold">
          Login erfolgreich!
        </div>
      )}
      <main className="w-full mx-auto px-4 sm:px-6 lg:px-8 mt-4 bg-bright dark:bg-background-dark dark:text-text-dark">
        <ScrollToTop />
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/glossar" element={<Glossary />} />
            <Route path="/glossar/:term" element={<GlossaryTerm />} />
            

            <Route path="/uber-uns" element={<UberUns />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/login" element={<Login />} />
            <Route path="/konto" element={<ProfileLayout />}>
              <Route index element={<Navigate to="/konto/profil" replace />} />
              <Route path="profil" element={<UserData />} />
              <Route path="uebersetzungen" element={<Translations />} />
              <Route path="abonnement" element={<Subscription />} />
              {/* <Route path="api-schluessel" element={<APIKeys />} /> */}
            </Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="/activate-account" element={<ActivateAccount />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/preise" element={<Pricing />} /> 
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success" element={<PaymentSuccess />} />
            
            
          </Routes>
        </Suspense>
      </main>
      <FooterComponent />
    </>
  );
}

function App() {
  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      localStorage.setItem('access_token', "");
    }

    if (!localStorage.getItem('refresh_token')) {
      localStorage.setItem('refresh_token', "");
    }
  }, []);
  

  return (
    <ThemeProvider>
      <AuthProvider>
        <AuthWrapper>
          <Router>
            <SpeedInsights/>
            <Analytics/>
            <AppContent />
          </Router>
        </AuthWrapper>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
