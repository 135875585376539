import React from 'react';
import Bars from '../loading/Bars';
import { useTranslation } from 'react-i18next';
const StatCard = ({ title, value, isLoading }) => {
  return (
    <div className="p-6 bg-gray-50 dark:bg-background-dark rounded-lg border border-gray-200 dark:border-gray-700">
      <div className="text-sm text-gray-600 dark:text-gray-400 h-12 flex items-center">
        {title}
      </div>
      <div className="text-2xl font-semibold h-12 flex items-center dark:text-text-dark">
        {isLoading ? (
          <div className="w-full h-6">
            <Bars size="small"/>
          </div>
        ) : (
          value.toLocaleString('de-DE')
        )}
      </div>
    </div>
  );
};

const StatsOverview = ({ stats, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="max-w-4xl mx-auto py-12">
      <h2 className="text-3xl font-bold text-center mb-8 text-text-bright dark:text-text-dark">{t('stats.title')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        <StatCard
          title="Vereinfachte Wörter"
          value={stats.totalWords}
          isLoading={isLoading}
        />
        <StatCard
          title="Vereinfachte Sätze"
          value={stats.totalSentences}
          isLoading={isLoading}
        />
        <StatCard
          title="Anzahl der Vereinfachungen"
          value={stats.totalTranslations}
          isLoading={isLoading}
        />
        {/* <StatCard
          title="Bemessen auf 'Der kleine Prinz'"
          value={stats.kleinerPrince}
          isLoading={isLoading}
        /> */}
      </div>
    </div>
  );
};

export default StatsOverview;
