export function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + ";" + expires + ";path=/";
}

export function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function cleanOldCookies(charUsage) {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  const keys = Object.keys(charUsage);
  for (const key of keys) {
    if (key !== currentMonth) {
      delete charUsage[key];
    }
  }
  return charUsage;
}

export function getCharacterCount() {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  const charUsage = JSON.parse(getCookie('charUsage') || '{}');
  console.log('Fetched charUsage:', charUsage); // Debugging
  return parseInt(charUsage[currentMonth] || 0, 10);
}

export function getCharactersLeftThisMonth(charLimit) {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  const charUsage = JSON.parse(getCookie('charUsage') || '{}');
  return charLimit - parseInt(charUsage[currentMonth] || 0, 10);
}

export function countCharacters(text) {
  return text.length;
}