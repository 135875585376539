import React from 'react';
import AuthorCard from './AuthorCard';

const LukasCard = () => (
  <AuthorCard
    name="Lukas Hecker"
    imageUrl="/imgs/other/lukas-foto.webp"
    description="Lukas ist Experte für Machine Learning und KI und hat sich auf den Bereich der Sprachmodellierung spezialisiert. Seit Jahren bringt er Maschinen das Sprechen bei, insbesondere zum Zweck der barrierefreien Kommunikation."
  />
);

export default LukasCard;
