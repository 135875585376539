import React from 'react';

const BioShort = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 mt-6 border-none">
      <h2 className="text-3xl font-bold text-center mb-8 dark:text-text-dark">Über Uns</h2>
      <p className="text-center text-gray-500 dark:text-gray-400 mb-8 text-base">
        Zwei Freunde. Eine Idee.<br /> Verständnis schaffen, Nachteile ausgleichen.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-background-bright-2 dark:bg-background-dark shadow-none border border-gray-300 dark:border-gray-700 rounded-lg p-6 text-center hover:shadow-md hover:border hover:border-gray-400 dark:hover:border-gray-600 transition-all duration-300">
          <img src="imgs/other/lukas-foto.webp" alt="Bild von Lukas Hecker" className="w-full max-w-[16rem] h-auto aspect-square rounded-lg mb-4 object-cover" />
          <h3 className="text-xl font-semibold mb-2 dark:text-text-dark">Lukas Hecker</h3>
          <p className="text-center text-gray-600 dark:text-gray-400 text-base">
            Experte für Machine Learning und KI.
          </p>
        </div>
        <div className="bg-background-bright-2 dark:bg-background-dark shadow-none border border-gray-300 dark:border-gray-700 rounded-lg p-6 text-center hover:shadow-md hover:border hover:border-gray-400 dark:hover:border-gray-600 transition-all duration-300">
          <img src="imgs/other/pascal-foto.webp" alt="Bild von Pascal Haller" className="w-full max-w-[16rem] h-auto aspect-square rounded-lg mb-4 object-cover" />
          <h3 className="text-xl font-semibold mb-2 dark:text-text-dark">Pascal Haller</h3>
          <p className="text-center text-gray-600 dark:text-gray-400 text-base">
            Führungskraft im Sozialwesen.
          </p>
        </div>
      </div>
      <p className="text-center text-gray-500 dark:text-gray-400 mt-8 text-base">
        Unser Ziel: Hürden abbauen und ein niederschwelliges Angebot Leichter Sprache schaffen.
      </p>
      {/* <div className="text-center mt-0">
        <a href="/uber-uns" className="text-gray-500 text-base" target="_blank" rel="noopener noreferrer">
          Mehr erfahren
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>
        </a>
      </div> */}
    </div>
  );
};

export default BioShort;
