import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faThreads } from '@fortawesome/free-brands-svg-icons';

const SocialShareButton = ({ icon, href, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-600 hover:text-gray-800 mr-4"
    aria-label={label}
  >
    <FontAwesomeIcon icon={icon} size="lg" />
  </a>
);

const SocialShareButtons = ({ shareUrl, shareText }) => {
  return (
    <div className="mt-8 text-center">
      <h3 className="text-xl font-semibold mb-3 text-gray-500">Teilen</h3>
      <div className="flex justify-center">
        <SocialShareButton
          icon={faTwitter}
          href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(shareText)}`}
          label="Auf Twitter teilen"
        />
        <SocialShareButton
          icon={faFacebook}
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          label="Auf Facebook teilen"
        />
        <SocialShareButton
          icon={faLinkedin}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${encodeURIComponent(shareText)}`}
          label="Auf LinkedIn teilen"
        />
        <SocialShareButton
          icon={faThreads}
        //   href={`https://www.threads.net/share?url=${shareUrl}`}
          href={`https://www.threads.net/intent/post?text=${shareText}`}
          label="Auf Threads teilen"
        />
      </div>
    </div>
  );
};

export default SocialShareButtons;