import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FooterComponent.css';  // Create this CSS file to style the footer

const FooterComponent = () => {
  return (
    <footer className="footer mt-auto pt-8 py-3 bg-bright dark:bg-background-dark z-10">
      <Container>
        <Row>
          <Col md={4}>
            <h4 className="text-black dark:text-text-dark">Über uns</h4>
            <ul className="list-unstyled text-base mt-3">
              <li><Link to="/mission" className="text-black dark:text-text-dark hover:underline hover:underline-offset-8">Mission</Link></li>
              <li><Link to="/datenschutz" className="text-black dark:text-text-dark hover:underline hover:underline-offset-8">Datenschutz</Link></li>
              <li><Link to="/impressum" className="text-black dark:text-text-dark hover:underline hover:underline-offset-8">Impressum</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4 className="text-black dark:text-text-dark">Support</h4>
            <ul className="list-unstyled text-base mt-3">
              <li><Link to="/preise" className="text-black dark:text-text-dark hover:underline hover:underline-offset-8">Preise</Link></li>
              <li><Link to="/contact" className="text-black dark:text-text-dark hover:underline hover:underline-offset-8">Kontakt</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4 className="text-black dark:text-text-dark">Folge uns</h4>
            <ul className="list-unstyled social-links text-base mt-3">
              <li><a href="https://www.linkedin.com/company/leichte-sprache-%C3%BCbersetzer/" 
                     target="_blank" 
                     rel="noopener noreferrer"
                     className="text-black text-base dark:text-text-dark hover:underline hover:underline-offset-8">LinkedIn</a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center text-sm mt-3">
            <p className="text-black dark:text-text-dark">&copy; 2024 Leichte Sprache Übersetzer. Alle Rechte vorbehalten.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
