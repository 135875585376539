// src/components/BlogList.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { posts } from '../../data/postsData'; // Import the posts data

const BlogList = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog | Leichte Sprache Übersetzer</title>
        <meta name="description" content="Blog - Leichte Sprache Übersetzer" />
        <meta name="keywords" content="Blog, Leichte Sprache Übersetzer, Einfach erklärt, Definition" />
        <meta property="og:title" content="Blog - Leichte Sprache Übersetzer" />
        <meta property="og:description" content="Blog - Leichte Sprache Übersetzer" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/component_screenshot.webp" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
      </Helmet>

      <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-100">Blog</h1>
      <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">In unserem Blog berichten wir über aktuelle Themen im Bereich digitaler Barrierefreiheit, Leichte Sprache und Einfache Sprache.</p>

      <div className="space-y-8">
        {posts.map((post) => (
          <Link to={`/blog/${post.slug}`} key={post.slug} className="block group no-underline">
            <article className="flex flex-col md:flex-row bg-white dark:bg-background-dark border border-gray-200 dark:border-gray-700 rounded-lg shadow-md overflow-hidden transition-shadow duration-300 hover:shadow-lg">
              <div className="md:w-1/3">
                <img src={post.img} alt={post.title} className="w-full h-48 md:h-full object-cover" />
              </div>
              <div className="p-6 md:w-2/3">
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2 no-underline">{post.date}</p>
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-3 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-300 no-underline">{post.title}</h2>
                <p className="text-gray-600 dark:text-gray-300 no-underline">{post.description}</p>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
