import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

import { charLimitFree, charLimitRegistered, BASE_URL } from '../../config';
import ComplexityCard from './ComplexityCard';
import { setCookie, getCookie, getCharactersLeftThisMonth, cleanOldCookies } from '../../utils/cookies';
import { useAuth } from '../../context/AuthContext';
import RadioButtonGroup from './RadioButtonGroup';
import { charsPerRequestRegistered, charsPerRequestUnregistered } from '../../config';
import Alert from '../alerts/Alert';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';


const LeichteSpracheTranslator = () => {
  const { isDarkMode } = useTheme();

  const [state, setState] = useState(() => {
    const savedState = Cookies.get('myAppState');
    return savedState ? JSON.parse(savedState) : { summarize: "1", mediopoint: false, languageLevel: 'leichte-sprache', showMarkdown: true };
  });

  useEffect(() => {
    Cookies.set('myAppState', JSON.stringify(state), { expires: 7 });
  }, [state]);

  const [text, setText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  const [translations, setTranslations] = useState('');
  const [translationsEinfach, setTranslationsEinfach] = useState('');
  const [translationsLeicht, setTranslationsLeicht] = useState('');
  const [translationsMarkdown, setTranslationsMarkdown] = useState('');
  const [translationsShown, setTranslationsShown] = useState('');
  
  const [complexity, setComplexity] = useState(0);
  const [GERLevel, setGERLevel] = useState('');
  const [grade, setGrade] = useState('');

  const [simplifiedComplexity, setSimplifiedComplexity] = useState(0);
  const [simplifiedGERLevel, setSimplifiedGERLevel] = useState('');
  const [simplifiedGrade, setSimplifiedGrade] = useState('');
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const { authState, getAccessToken } = useAuth();
  const { isLoggedIn } = authState;  // const navigate = useNavigate();

  const [alerts, setAlerts] = useState([]);

  // Add new state variables for storing complexity metrics
  const [leichteComplexity, setLeichteComplexity] = useState({});
  const [einfacheComplexity, setEinfacheComplexity] = useState({});

  // Add this to the existing state declarations
  const [lastTranslatedText, setLastTranslatedText] = useState('');

  // localization 
  const { t } = useTranslation();

  const charsPerPage = isLoggedIn ? charsPerRequestRegistered : charsPerRequestUnregistered;
  const charLimit = isLoggedIn ? charLimitRegistered : charLimitFree;

  useEffect(() => {
    textAreaRef.current.focus();
  }, []);
  
    
  const handleToggle = () => {
    setState(prevState => {
      const newMediopoint = !prevState.mediopoint;
      const updatedText = toggleHyphenMediopoint(translationsShown, newMediopoint);
      setTranslationsShown(updatedText);
      console.log("Toggle mediopoint: ", newMediopoint);
      return { ...prevState, mediopoint: newMediopoint };
    });
  };

  // const handleMarkdownToggle = () => {
    
  //   if (state.showMarkdown) {
  //     setTranslationsShown(translations);
  //   } else {
  //     setTranslationsShown(translationsMarkdown);
  //   };
  //   setState(prevState => ({ ...prevState, showMarkdown: !prevState.showMarkdown }));
  // };

  const DEBOUNCE_DELAY = 500; // 0.5 seconds for debouncing
  const FAKE_LOAD_DELAY = 200; // 0.2 seconds for fake loading
  
  // Add this state to track the timeout
  const [translateTimeout, setTranslateTimeout] = useState(null);

  useEffect(() => {
    return () => {
      if (translateTimeout) {
        clearTimeout(translateTimeout);
      }
    };
  }, [translateTimeout]);

  // Add new state for background translations
  // const [backgroundTranslationsLeicht, setBackgroundTranslationsLeicht] = useState('');
  // const [backgroundTranslationsEinfach, setBackgroundTranslationsEinfach] = useState('');

  // Add state to store full background translation data
  const [backgroundTranslationDataLeicht, setBackgroundTranslationDataLeicht] = useState(null);
  const [backgroundTranslationDataEinfach, setBackgroundTranslationDataEinfach] = useState(null);

  // Add state for tracking ongoing background translation
  const [pendingTranslation, setPendingTranslation] = useState(null);

  const performBackgroundTranslation = async (textToTranslate) => {
    // if number of words is less than 4, use glossary lookup instead
    const wordCount = textToTranslate.trim().split(/\s+/).length;
    if (wordCount < 4) {
      console.log("Short query detected, not performing background translation");
      // handleGlossaryLookup(textToTranslate.trim());
      return;
    }
    console.log("Starting background translation");
    try {
      const token = await getAccessToken();
      const translationPromise = Promise.all([
        performTranslation(token, textToTranslate, 'leichte-sprache', false),
        performTranslation(token, textToTranslate, 'einfache-sprache-2', false)
      ]);

      setPendingTranslation({
        text: textToTranslate,
        promise: translationPromise,
        summarize: state.summarize // Store the summarization level with the pending translation
      });

      const [leichtResponse, einfachResponse] = await translationPromise;

      if (leichtResponse.status === 200 && einfachResponse.status === 200) {
        console.log("Background translation completed");
        setBackgroundTranslationDataLeicht(leichtResponse.data[0]);
        setBackgroundTranslationDataEinfach(einfachResponse.data[0]);
        setLastTranslatedText(textToTranslate);
      }

      setPendingTranslation(null);
      return [leichtResponse, einfachResponse];
    } catch (error) {
      console.error("Background translation error:", error);
      setPendingTranslation(null);
      throw error;
    }
  };

  // now uses background translation
  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= charsPerPage && newText.trim().length > 0) {
      setText(newText);
      setCharCount(newText.length);
      
      if (translateTimeout) {
        clearTimeout(translateTimeout);
      }

      // const newTimeout = setTimeout(() => {
      //   console.log('Background translation triggered');
      //   performBackgroundTranslation(newText);
      // }, DEBOUNCE_DELAY);
      const newTimeout = setTimeout(async () => {
        // Check available characters before proceeding
        const token = await getAccessToken();
        const { totalCharsAvailable } = await getAvailableCharacters(token, newText.length);
        
        if (totalCharsAvailable >= newText.length) {
          console.log("Enough characters available for background translation");
          performBackgroundTranslation(newText);
        } else {
          console.log("Not enough characters available for background translation");
        }
      }, DEBOUNCE_DELAY);
      
      setTranslateTimeout(newTimeout);
    } else if (newText.length > charsPerPage && newText.trim().length > 0) {
      setText(newText.slice(0, charsPerPage));
      setCharCount(charsPerPage);
      const alertMessage = isLoggedIn
        ? `Der Text war zu lang. Er wurde auf ${charsPerPage} Zeichen gekürzt.`
        : `Der Text war zu lang. Er wurde auf ${charsPerPage} Zeichen gekürzt.<br />Bitte <a href="/login" class="text-blue-500 hover:underline">melde dich an</a> oder registriere dich.<br />Dann kannst du mehr Zeichen auf einmal übersetzen.`;

      addAlert('Zeichen·Grenze erreicht', alertMessage, 'default');
    } else {
      setText('');
      setCharCount(0);
    }
  };

  // Modify handlePaste similarly
  const handlePaste = useCallback((e) => {
    const pastedText = e.clipboardData.getData('text');
    
    if (translateTimeout) {
      clearTimeout(translateTimeout);
    }

    // const newTimeout = setTimeout(() => {
    //   performBackgroundTranslation(pastedText.slice(0, charsPerPage));
    // }, DEBOUNCE_DELAY);
    const newTimeout = setTimeout(async () => {
      const token = await getAccessToken();
      const { totalCharsAvailable } = await getAvailableCharacters(token, pastedText.length);
      
      if (totalCharsAvailable >= pastedText.length) {
        console.log("Enough characters available for background translation");
        performBackgroundTranslation(pastedText.slice(0, charsPerPage));
      } else {
        console.log("Not enough characters available for background translation");
      }
    }, DEBOUNCE_DELAY);
    
    setTranslateTimeout(newTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateTimeout, charsPerPage]);

  const handleSummarize = (newState) => {
    setState(prevState => ({ ...prevState, summarize: newState }));
    
    // If there's text, trigger a new background translation with the new summarization level
    if (text.trim()) {
      if (translateTimeout) {
        clearTimeout(translateTimeout);
      }
      
      const newTimeout = setTimeout(() => {
        performBackgroundTranslation(text);
      }, DEBOUNCE_DELAY);
      
      setTranslateTimeout(newTimeout);
    }
  };

  // const handleLanguageChange = (newLevel) => {
  //   // setLanguage(event.target.value);
  //   setState(prevState => ({ ...prevState, languageLevel: newLevel }));
  // };

  const handleLanguageChange = (newLevel) => {
    // setLanguage(event.target.value);
    setState(prevState => ({ ...prevState, languageLevel: newLevel }));
    if (newLevel === 'einfache-sprache-2') {
      setTranslationsShown(translationsEinfach);
    } else {
      setTranslationsShown(translationsLeicht);
    }
    // Update complexity metrics when switching language levels
    updateComplexityMetrics(newLevel);
  };

  // const getLanguageText = (language) => {
  //   if (language === "leichte-sprache") {
  //     return "Für Menschen mit Lern-Schwierigkeiten oder mit Legasthenie.";
  //   } else if (language === "einfache-sprache") {
  //     return "Für Menschen, die komplexe Texte schwer verstehen.";
  //   } else if (language === "einfache-sprache-2") {
  //     return "Für Menschen, die komplexe Texte schwer verstehen.";
  //   }
  //   return "";
  // };

  const handleCopy = async () => {
    const appendText = `\n\nDieser Text wurde mit dem Leichte Sprache Übersetzer vereinfacht: https://www.leichte-sprache-uebersetzer.de/`;
    if (state.showMarkdown) { 
      navigator.clipboard.writeText(stripHtmlTags(translationsMarkdown + appendText));
    } else {
      navigator.clipboard.writeText(translations + appendText);
    }
    const token = await getAccessToken();
    const headers = { Authorization: `Bearer ${token}` };

    const copiedData = {
      source_text: text,
      translated_text: translations,
      translated_text_formatted: translationsMarkdown,
      language_level: state.languageLevel,
      summarize: state.summarize,
      formatted: state.showMarkdown,
    };

    // Send the POST request without waiting for a response
    axios.post(`${BASE_URL}/copy`, copiedData, { headers })
      .then(() => console.log("Copy request sent"))
      .catch(error => console.error("Error sending copy request:", error));

    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Hide notification after 2 seconds
  };

  // Modify handleTranslate to check for pending translations
  const handleTranslate = async (inputText = null) => {
    const textToTranslate = getTextToTranslate(inputText);
    let isGlossaryLookup = false;
    let leichtResponse, einfachResponse;

    if (!textToTranslate) {
      console.log("No text to translate");
      return;
    }

    // Check available characters before proceeding
    const token = await getAccessToken();
    const { totalCharsAvailable } = await getAvailableCharacters(token, textToTranslate.length);
    
    if (totalCharsAvailable < textToTranslate.length) {
      handleInsufficientCharacters();
      console.log("Not enough characters available (", totalCharsAvailable, " < ", textToTranslate.length, ")");
      return;
    } else {
      console.log("Enough characters available (", totalCharsAvailable, " >= ", textToTranslate.length, ")");
    }

    setIsLoading(true);
    // const token = await getAccessToken();
    try {
      // Count words by splitting on whitespace and filtering out empty strings
      const wordCount = textToTranslate.trim().split(/\s+/).length;
      // For very short queries, use glossary lookup instead
      if (wordCount < 4) {
        console.log("Short query detected, using glossary lookup");
        isGlossaryLookup = true;
        await handleGlossaryLookup(textToTranslate.trim());
        setIsLoading(false);
        return;
      }

      // Check if there's a pending translation for the same text
      if (pendingTranslation && pendingTranslation.text.trim() === textToTranslate.trim()) {
        console.log("Waiting for pending translation to complete");
        const responses = await pendingTranslation.promise;
        if (!responses || !Array.isArray(responses)) {
          console.error("Invalid pending translation response");
          throw new Error('Invalid translation response');
        }
        [leichtResponse, einfachResponse] = responses;
        
        await new Promise(resolve => setTimeout(resolve, FAKE_LOAD_DELAY));
        updateTranslationState(leichtResponse.data[0], einfachResponse.data[0]);
        setIsLoading(false);
      }

      // Check if we have a cached translation
      if (textToTranslate.trim() === lastTranslatedText.trim() && 
          backgroundTranslationDataLeicht && 
          backgroundTranslationDataEinfach) {
        console.log("Using cached background translation");
        await new Promise(resolve => setTimeout(resolve, FAKE_LOAD_DELAY));
        
        updateTranslationState(
          backgroundTranslationDataLeicht,
          backgroundTranslationDataEinfach
        );

        // Handle character deduction here before returning
        const { freeCharsAvailable, paidCharsAvailable } = await getAvailableCharacters(token, textToTranslate.length);
        handleSuccessfulTranslation(token, textToTranslate.length, freeCharsAvailable, paidCharsAvailable);

        setIsLoading(false);
        return; // Add return statement to prevent unnecessary translation
      }

      // No pending translation or cached translation found, perform new translation
      [leichtResponse, einfachResponse] = await Promise.all([
        performTranslation(token, textToTranslate, 'leichte-sprache', false),
        performTranslation(token, textToTranslate, 'einfache-sprache-2', false)
      ]);

      updateTranslationState(leichtResponse.data[0], einfachResponse.data[0]);
      setIsLoading(false);

    } catch (error) {
      console.error("Translation error:", error);
      setIsLoading(false);
      addAlert('Fehler', 'Beim Übersetzen ist ein Fehler aufgetreten. Bitte versuche es später nochmal.', 'error');
    }

    // Handle character deduction
    const { freeCharsAvailable, paidCharsAvailable } = await getAvailableCharacters(token, textToTranslate.length);
    handleSuccessfulTranslation(token, textToTranslate.length, freeCharsAvailable, paidCharsAvailable);

    // Log the translation
    // log_current_translation()
    if (!isGlossaryLookup) {
      console.log("Logging translation attempt: ", leichtResponse.data[0]);
      axios.post(`${BASE_URL}/translate/log`,
        [leichtResponse.data[0], einfachResponse.data[0]]
      );
    } else {
      // we will do that later
      // axios.post(`${BASE_URL}/glossary/log`,
      //   recentGlossaryData,
      // { });
    }
    return
  };

  const handleGlossaryLookup = async (term) => {
    try {
      const token = await getAccessToken();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(`${BASE_URL}/glossary`, { term }, { headers });

      if (response.status === 200 && response.data.length > 0) {
        const glossaryData = response.data[0];
        
        // Preserve line breaks by replacing \n with <br> for HTML rendering
        const leichtExplanation = glossaryData.explanation_leicht?.replace(/\n/g, '<br>') || '';
        const einfachExplanation = glossaryData.explanation_einfach?.replace(/\n/g, '<br>') || '';
        
        // Update translations state
        setTranslationsLeicht(leichtExplanation);
        setTranslationsEinfach(einfachExplanation);

        // Create complexity objects first
        const newLeichteComplexity = {
          complexity: 100 - glossaryData.complexity.leicht.average_readability_score,
          gerLevel: glossaryData.complexity.leicht.ger_language_level,
          grade: glossaryData.complexity.leicht.estimated_grade_level,
          beforeComplexity: 100 - glossaryData.complexity.before.average_readability_score,
          beforeGerLevel: glossaryData.complexity.before.ger_language_level,
          beforeGrade: glossaryData.complexity.before.estimated_grade_level,
        };

        const newEinfacheComplexity = {
          complexity: 100 - glossaryData.complexity.einfach.average_readability_score,
          gerLevel: glossaryData.complexity.einfach.ger_language_level,
          grade: glossaryData.complexity.einfach.estimated_grade_level,
          beforeComplexity: 100 - glossaryData.complexity.before.average_readability_score,
          beforeGerLevel: glossaryData.complexity.before.ger_language_level,
          beforeGrade: glossaryData.complexity.before.estimated_grade_level,
        };

        // Update complexity states
        setLeichteComplexity(newLeichteComplexity);
        setEinfacheComplexity(newEinfacheComplexity);

        // Immediately update current complexity metrics
        const currentComplexity = state.languageLevel === 'leichte-sprache' 
          ? newLeichteComplexity 
          : newEinfacheComplexity;
        
        setComplexity(currentComplexity.beforeComplexity);
        setGERLevel(currentComplexity.beforeGerLevel);
        setGrade(currentComplexity.beforeGrade);
        
        setSimplifiedComplexity(currentComplexity.complexity);
        setSimplifiedGERLevel(currentComplexity.gerLevel);
        setSimplifiedGrade(currentComplexity.grade);
        
        // Set the shown translation based on the current language level
        const currentTranslation = state.languageLevel === 'leichte-sprache' 
          ? leichtExplanation
          : einfachExplanation;
        
        setTranslationsMarkdown(currentTranslation);
        setTranslations(currentTranslation);
        setTranslationsShown(currentTranslation);

      } else {
        console.log("No glossary entry found for the term");
        // Reset all states when no entry is found
        setTranslationsLeicht('');
        setTranslationsEinfach('');
        setTranslationsMarkdown('');
        setTranslations('');
        setTranslationsShown('Keine Erklärung gefunden.');
        
        // Reset complexity metrics
        setComplexity(0);
        setGERLevel('');
        setGrade('');
        setSimplifiedComplexity(0);
        setSimplifiedGERLevel('');
        setSimplifiedGrade('');
      }
    } catch (error) {
      console.error("Error fetching glossary data:", error);
      setTranslationsShown('Ein Fehler ist aufgetreten. Bitte versuche es später nochmal.');
      // Reset complexity metrics on error
      setComplexity(0);
      setGERLevel('');
      setGrade('');
      setSimplifiedComplexity(0);
      setSimplifiedGERLevel('');
      setSimplifiedGrade('');
    }
  };

  // Helper functions

  const getTextToTranslate = (inputText) => {
    if (inputText === null || typeof inputText === 'object') {
      return text;
    }
    return inputText;
  };

  const getQuota = useCallback(async (token) => {
    const now = Date.now();
    const cacheExpiration = 5 * 60 * 1000; // 5 minutes

    const cachedQuota = localStorage.getItem('quotaCache');
    const lastCheck = localStorage.getItem('lastQuotaCheck');

    if (cachedQuota && lastCheck && (now - parseInt(lastCheck) < cacheExpiration)) {
      return parseInt(cachedQuota);
    }

    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.post(`${BASE_URL}/token/quota`, {  }, { headers });
    const newQuota = response.data[0].tokens;

    localStorage.setItem('quotaCache', newQuota.toString());
    localStorage.setItem('lastQuotaCheck', now.toString());

    return newQuota;
  }, []);

  const getAvailableCharacters = async (token, chars) => {
    let charLimit, freeCharsAvailable, paidCharsAvailable, totalCharsAvailable;

    if (!token) {
      charLimit = charLimitFree;
      freeCharsAvailable = getCharactersLeftThisMonth(charLimit);
      totalCharsAvailable = freeCharsAvailable;
      paidCharsAvailable = 0;
    } else {
      charLimit = charLimitRegistered;
      freeCharsAvailable = getCharactersLeftThisMonth(charLimit);
      paidCharsAvailable = await getQuota(token);
      totalCharsAvailable = paidCharsAvailable + freeCharsAvailable;
    }

    console.log(`Characters: current=${chars}, free=${freeCharsAvailable}, paid=${paidCharsAvailable}, total=${totalCharsAvailable}`);
    return { totalCharsAvailable, freeCharsAvailable, paidCharsAvailable };
  };

  const handleInsufficientCharacters = () => {
    const description = isLoggedIn
      ? 'Der Text überschreitet deine monatliche Zeichen·Grenze.'
      : 'Der Text überschreitet deine monatliche Zeichen·Grenze.<br />Bitte <a href="/login" class="text-blue-500 hover:underline">melde dich an</a> oder registriere dich.<br />Du bekommst dann kostenlos doppelt so viele Zeichen.';
    addAlert('Zeichen·Grenze erreicht', description, 'default');
  };

  const performTranslation = async (token, textToTranslate, languageLevel, log = false) => {
    const headers = { Authorization: `Bearer ${token}` };
    const translateData = {
      text: textToTranslate,
      language_level: languageLevel,
      summarize: parseInt(state.summarize),
      log: log
    };
    console.log("Calling translate endpoint: ", translateData.language_level);
    return await axios.post(`${BASE_URL}/translate`, translateData, { headers });
  };

  const handleSuccessfulTranslation = (token, chars, freeCharsAvailable, paidCharsAvailable) => {
    if (token) {
      deductCharacters(token, chars, freeCharsAvailable, paidCharsAvailable);
    } else {
      updateCharacterCount(chars);
      console.log("Free characters deducted successfully: ", chars);
    }
  };

  const deductCharacters = async (token, chars, freeCharsAvailable, paidCharsAvailable) => {
    let charsToDeductFromPaid = Math.min(chars, paidCharsAvailable);
    let charsToDeductFromFree = chars - charsToDeductFromPaid;

    if (charsToDeductFromPaid > 0) {
      try {
        await axios.post(
          `${BASE_URL}/token/change-quota`,
          { n_characters: -charsToDeductFromPaid },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("Paid characters deducted successfully: ", charsToDeductFromPaid);
      } catch (error) {
        console.error("Error deducting paid characters:", error);
      }
    }

    if (charsToDeductFromFree > 0) {
      updateCharacterCount(charsToDeductFromFree);
      console.log("Free characters deducted successfully: ", charsToDeductFromFree);
      // free characters left:
      console.log("Free characters left: ", getCharactersLeftThisMonth(charLimit), "out of ", charLimit);
    }
  };

  const updateTranslationState = (leichtData, einfachData) => {
    setTranslationsLeicht(leichtData["formatted_text"].replace("\n", ""));
    setTranslationsEinfach(einfachData["formatted_text"].replace("\n", ""));
    
    // First update the complexity data
    const newLeichteComplexity = {
      complexity: 100 - leichtData["complexity"]["after"]["average_readability_score"],
      gerLevel: leichtData["complexity"]["after"]["ger_language_level"],
      grade: leichtData["complexity"]["after"]["estimated_grade_level"],
      beforeComplexity: 100 - leichtData["complexity"]["before"]["average_readability_score"],
      beforeGerLevel: leichtData["complexity"]["before"]["ger_language_level"],
      beforeGrade: leichtData["complexity"]["before"]["estimated_grade_level"],
    };

    const newEinfacheComplexity = {
      complexity: 100 - einfachData["complexity"]["after"]["average_readability_score"],
      gerLevel: einfachData["complexity"]["after"]["ger_language_level"],
      grade: einfachData["complexity"]["after"]["estimated_grade_level"],
      beforeComplexity: 100 - einfachData["complexity"]["before"]["average_readability_score"],
      beforeGerLevel: einfachData["complexity"]["before"]["ger_language_level"],
      beforeGrade: einfachData["complexity"]["before"]["estimated_grade_level"],
    };

    setLeichteComplexity(newLeichteComplexity);
    setEinfacheComplexity(newEinfacheComplexity);

    // Immediately update the current complexity metrics based on the new data
    const currentComplexity = state.languageLevel === 'leichte-sprache' ? newLeichteComplexity : newEinfacheComplexity;
    
    setComplexity(currentComplexity.beforeComplexity);
    setGERLevel(currentComplexity.beforeGerLevel);
    setGrade(currentComplexity.beforeGrade);
    
    setSimplifiedComplexity(currentComplexity.complexity);
    setSimplifiedGERLevel(currentComplexity.gerLevel);
    setSimplifiedGrade(currentComplexity.grade);

    // Set translations after complexity is updated
    const currentTranslation = state.languageLevel === 'leichte-sprache' ? leichtData : einfachData;
    setTranslationsMarkdown(currentTranslation["formatted_text"].replace("\n", ""));
    setTranslations(currentTranslation["formatted_text"].replace("\n", ""));
    setTranslationsShown(state.showMarkdown ? currentTranslation["formatted_text"] : currentTranslation["simplified_text"]);

    setLastTranslatedText(text);
  };

  const exampleTexts = [
    "Willkommen bei unserem Leichte-Sprache-Übersetzer!\nUnser Ziel ist es, Leichte Sprache für alle zugänglich zu machen.\n\nMithilfe moderner Sprachmodelle, die ständig weiterentwickelt werden, übersetzen wir Texte in leicht verständliche Sprache. Probiere es aus!",
    "Leichte Sprache ist eine Form der deutschen Sprache, die besonders einfach ist. Sie hilft Menschen, die Schwierigkeiten beim Verstehen haben. Leichte Sprache folgt festen Regeln und verwendet einfache Wörter und kurze Sätze.",
    "Wir speichern deine Daten nur für den Zeitraum, der für die Erbringung unserer Dienstleistung notwendig ist. Nach Ablauf dieser Frist werden deine Daten sicher gelöscht. Deine Daten werden nicht an Dritte weitergegeben."
  ];

  const handleExampleClick = (example) => {
    console.log("Example clicked: ", example);
    setText(example);
    setCharCount(example.length);
    handleTranslate(example);
  };

  const addAlert = (title, description, variant, expireTime = null) => {
    const id = Date.now();
    setAlerts(prevAlerts => [...prevAlerts, { id, title, description, variant, expireTime }]);
  };

  const removeAlert = (id) => {
    setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
  };

  // Modify the updateComplexityMetrics function to accept glossary data
  const updateComplexityMetrics = (languageLevel, glossaryComplexity = null) => {
    if (glossaryComplexity) {
      // Use glossary complexity data if available
      setComplexity(glossaryComplexity.beforeComplexity || 0);
      setGERLevel(glossaryComplexity.beforeGerLevel || '');
      setGrade(glossaryComplexity.beforeGrade || '');
      
      setSimplifiedComplexity(glossaryComplexity.afterComplexity || 0);
      setSimplifiedGERLevel(glossaryComplexity.afterGerLevel || '');
      setSimplifiedGrade(glossaryComplexity.afterGrade || '');
    } else {
      // Use existing logic for longer texts
      const complexityData = languageLevel === 'leichte-sprache' ? leichteComplexity : einfacheComplexity;
      
      setComplexity(complexityData.beforeComplexity);
      setGERLevel(complexityData.beforeGerLevel);
      setGrade(complexityData.beforeGrade);
      
      setSimplifiedComplexity(complexityData.complexity);
      setSimplifiedGERLevel(complexityData.gerLevel);
      setSimplifiedGrade(complexityData.grade);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left Panel */}
        <div className="w-full lg:w-1/2">
          <div className={`${isDarkMode ? 'bg-background-dark border-gray-600' : 'bg-background-bright border-gray-400'} p-6 pt-4 h-full shadow-md rounded-md`} 
               style={{border: isDarkMode ? '1px solid #404040' : '1px solid #d1d5db'}}>
            <div className="flex gap-2 justify-end">
              {exampleTexts.map((example, index) => (
                <button
                  key={index}
                  onClick={() => handleExampleClick(example)}
                  className={`${isDarkMode ? 'bg-background-dark hover:bg-gray-800 text-text-dark' : 'bg-white hover:bg-gray-200 text-gray-600'} 
                    text-sm py-1 px-2 rounded-md border border-gray-400`}
                >
                  Beispiel {index + 1}
                </button>
              ))}
            </div>
            <div className="relative pt-4">
              <textarea
                id="textAreaExample"
                rows={10}
                ref={textAreaRef}
                value={text}
                onChange={handleTextChange}
                onPaste={handlePaste}
                placeholder='Gib hier deinen Text ein.'
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.ctrlKey) {
                    handleTranslate();
                  }
                }}
                className={`w-full p-3 border rounded-md resize-none shadow-sm text-base placeholder:text-gray-500 dark:placeholder:text-gray-400 focus:outline-none focus:ring-2 
                  focus:ring-primary-light-2 hover:shadow-md
                  ${isDarkMode ? 'bg-background-dark text-text-dark border-gray-600' : 'bg-background-bright-2 text-black border-gray-300'}`}
              />
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-2 mb-4">
                <div className="mb-2 -mt-4 ml-1 sm:mb-0">
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {charCount} / {charsPerPage} Zeichen
                  </span>
                </div>
              </div>
            </div>
            
            {/* Radio Button Group and Translate Button container */}
            <div className="flex flex-col md:flex-row justify-between items-center mt-4">
              <RadioButtonGroup defaultValue={state.summarize} onChange={handleSummarize} />
              <button 
                onClick={handleTranslate} 
                disabled={isLoading} 
                className="w-1/4 h-10 text-sm mt-4 ml-8 md:mt-0 shadow-md bg-primary hover:bg-primary-dark-1 hover:shadow-none border-none text-white py-2 px-4 rounded-md whitespace-nowrap overflow-hidden text-ellipsis"
                title="Einfacher machen"
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  'Einfacher machen'
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2">
          <div className={`${isDarkMode ? 'bg-background-dark border-gray-600' : 'bg-background-bright border-gray-400'} px-6 pb-4 h-full shadow-md rounded-md`}
               style={{border: isDarkMode ? '1px solid #404040' : '1px solid #d1d5db'}}>
            <div className="flex flex-col md:flex-row items-center justify-between mb-5 mt-0 space-y-4 md:space-y-4 md:space-x-4">
              <div className="flex mt-2">
                <button 
                  onClick={() => handleLanguageChange('leichte-sprache')}
                  className={`${isDarkMode ? 'bg-background-dark text-text-dark hover:bg-gray-800' : 'bg-background-bright text-black hover:bg-gray-200'} 
                    text-sm border-none p-2 rounded-md  
                    ${state.languageLevel === 'leichte-sprache' ? 'underline underline-offset-8' : ''}`}>
                  Leicht
                </button>

                <button 
                  onClick={() => handleLanguageChange('einfache-sprache-2')}
                  className={`${isDarkMode ? 'bg-background-dark text-text-dark hover:bg-gray-800' : 'bg-background-bright text-black hover:bg-gray-200'} 
                    text-sm border-none p-2 rounded-md  
                    ${state.languageLevel === 'einfache-sprache-2' ? 'underline underline-offset-8' : ''}`}>
                  Einfach
                </button>
              </div>

              {/* Toggle switch container */}
              <div className={`flex items-center ${isDarkMode ? 'text-text-dark' : 'text-black'}`}>
                <label className="mr-2 text-sm">
                  Binde-Strich
                </label>
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input 
                    type="checkbox" 
                    id="medio-switch" 
                    checked={!state.mediopoint}
                    onChange={handleToggle}
                    className="sr-only"
                  />
                  <label 
                    htmlFor="medio-switch" 
                    className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  >
                    <span 
                      className={`block h-6 w-6 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out border border-gray-400 ${
                        state.mediopoint ? 'translate-x-4' : 'translate-x-0'
                      }`}
                    ></span>
                  </label>
                </div>
                <label className="ml-0 text-sm">
                  Medio·Punkt
                </label>
              </div>
            </div>

            <div className="relative">
              <div className={`p-3 text-base shadow-sm border rounded-md min-h-[262px] max-h-[262px] overflow-y-auto 
                 relative
                ${isDarkMode ? 'bg-background-dark text-text-dark border-gray-600' : 'bg-background-bright-2 text-black border-gray-300'}`}>
                {translationsShown ? (
                  <div className="[&>h1]:text-xl [&>h1]:font-bold [&>h1]:mb-3 [&>h2]:text-base [&>h2]:font-semibold [&>h2]:mb-2" dangerouslySetInnerHTML={{ __html: translationsShown }} />
                ) : (
                  <div className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                    Vereinfachter Text
                  </div>
                )}
              </div>
              <Button
                onClick={handleCopy}
                className={`absolute bottom-4 right-4 bg-transparent hover:bg-gray-200 
                  ${isDarkMode ? 'text-text-dark hover:bg-gray-800' : 'text-gray-800'} p-2 rounded border-0`}
              >
                <FontAwesomeIcon icon={copySuccess ? faCheck : faCopy} />
              </Button>
              {copySuccess && (
                <div className="absolute bottom-0 right-4 bg-black text-text-dark px-2 py-1 rounded text-sm">
                  Kopiert!
                </div>
              )}
            </div>
            <p className={`text-sm mt-4 pl-2 ${isDarkMode ? 'text-text-dark' : 'text-black'}`}>
              Zusätzliche Begriffs-Erklärungen findest du in unserem <a href="/glossar" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Wörter-Buch.</a>
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mt-6">
        <span className={`text-sm max-w-4xl mx-auto block px-4 ${isDarkMode ? 'text-text-dark' : 'text-black'}`} 
              dangerouslySetInnerHTML={{ __html: t('translatorComponent.paragraph') }}>
        </span>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 mt-6">
        <div className="w-full lg:w-1/2">
          <ComplexityCard title="Vorher" complexity={complexity} level={GERLevel} grade={grade} alignment="left" />
        </div>
        <div className="w-full lg:w-1/2">
          <ComplexityCard title="Nachher" complexity={simplifiedComplexity} level={simplifiedGERLevel} grade={simplifiedGrade} alignment="right" />
        </div>
      </div>

      {alerts.map(alert => (
        <Alert
          key={alert.id}
          title={alert.title}
          description={alert.description}
          variant={alert.variant}
          expireTime={alert.expireTime}
          onClose={() => removeAlert(alert.id)}
        />
      ))}

      
    </div>
  );  
};

export default LeichteSpracheTranslator;


function toggleHyphenMediopoint(text, toMediopoint) {
  if (text === '' || text === null || text === undefined) {
    return text;
  }
  if (toMediopoint) {
    // Replace hyphens with mediopoints between letters
    return text.replace(/([a-zA-Z])-([a-zA-Z])/g, '$1·$2');
  } else {
    // Replace mediopoints with hyphens between letters
    return text.replace(/([a-zA-Z])·([a-zA-Z])/g, '$1-$2');
  }
}

function updateCharacterCount(chars) {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  let charUsage = JSON.parse(getCookie('charUsage') || '{}');

  // Clean old cookies
  charUsage = cleanOldCookies(charUsage);

  if (!charUsage[currentMonth]) {
    charUsage[currentMonth] = 0;
  }

  charUsage[currentMonth] += chars;

  setCookie('charUsage', JSON.stringify(charUsage), 30); // Store for 30 days
  // console.log('Updated charUsage:', charUsage); // Debugging
}

function stripHtmlTags(html) {
  // Step 1: Remove all HTML tags except for <br>, <p>, <div>, <ul>, <ol>, <li>, <h1>, <h2>, <h3>, etc.
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  
  // Step 2: Replace <br>, <p>, <div> with line breaks
  let text = tempDiv.innerHTML.replace(/<br\s*\/?>/gi, '\n')
                              .replace(/<\/p>|<\/div>/gi, '\n')
                              .replace(/<p>|<div>/gi, '');
  
  // Step 3: Add line breaks before and after headings
  text = text.replace(/<\/h[1-6]>/gi, '\n\n')
             .replace(/<h[1-6]>/gi, '\n');

  // Step 4: Convert <ul> and <ol> to line breaks and <li> to dashes
  text = text.replace(/<\/ul>|<\/ol>/gi, '\n')  // Replace </ul> and </ol> with line breaks
             .replace(/<ul>|<ol>/gi, '')  // Remove <ul> and <ol>
             .replace(/<li>/gi, '- ')  // Replace <li> with dashes
             .replace(/<\/li>/gi, '\n');  // Replace <li> with line breaks

  // Step 5: Remove remaining HTML tags
  text = text.replace(/<\/?[^>]+(>|$)/g, "");

  // Step 6: Trim leading and trailing whitespace
  text = text.trim();

  return text;
}

